// extracted by mini-css-extract-plugin
export var timelineCard = "style-module--timeline-card--bf151";
export var timelineDecade = "style-module--timeline-decade--1e75e";
export var timelineDecadeActive = "style-module--timeline-decade-active--c4cf1";
export var timelineDecadeImg = "style-module--timeline-decade-img--25494";
export var timelineDescription = "style-module--timeline-description--3d4fb";
export var timelineSlider = "style-module--timeline-slider--1e825";
export var timelineSources = "style-module--timeline-sources--a7599";
export var timelineTitle = "style-module--timeline-title--5f8cb";
export var timelineWrapper = "style-module--timeline-wrapper--c6b0e";
export var timelineYear = "style-module--timeline-year--ced16";