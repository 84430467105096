export type TimelineComic = {
  title: string
  year: number | null
  author?: string
  author2?: string
  author3?: string
  author4?: string
}
export const data: TimelineComic[] = [
  {
    title: '2112',
    year: 2011,
    author: 'Joni B',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: '¡Apagón horas aburridas!',
    year: 1992,
    author: 'Chócolo',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: '¡Bien!',
    year: 1993,
    author: 'Diego Tobón',
    author2: 'Flakoz',
    author4: 'Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: '¡Chao, clóset!',
    year: 2022,
    author: 'Sebastián Santafe Ayala',
  },
  {
    title: '¡Horas aburridas!',
    year: 1992,
    author: 'Naide',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: '¡Luz pasó por aqui, cate que no te vi!',
    year: 1992,
    author: 'Ceci',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: '¡Qué Bárbaro!',
    year: 1989,
    author: 'Jorge Grosso',
    author2: 'Quir',
    author3: 'Ceci',
    author4: 'Calero Nicholls Roa Rincón',
  },
  {
    title: '¿Quién habla?',
    year: 2005,
    author: 'Muyi Neira (Ilustrador)',
    author2: 'Javier Moreno (guion)',
  },
  {
    title: '100 años',
    year: 1997,
    author: 'Eneas (texto e ilustraciones)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Alejandro Gutiérrez (portada) (México)',
  },
  {
    title: '100 años',
    year: 1997,
    author: 'Muyi',
    author2: 'Bernardo Rincón (director)',
    author3: 'Alejandro Gutiérrez (portada) (México)',
  },
  {
    title: '2comics',
    year: 2000,
    author: 'Erners',
  },
  {
    title: '31-12-2010/1-1-2011',
    year: 2011,
    author: 'PowerPaola',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: '4 extraños en DC',
    year: 2004,
    author: 'Jaime Moreno',
  },
  {
    title: '4 mesas',
    year: 2020,
    author: 'Colectivo 4 Mesas',
    author2:
      'Henry Díaz (edición y portada) Pablo Guerra (dirección y prólogo)',
    author3:
      'Andrés Álvarez y Luisa Lozano (diagramación) María Vanegas (diseño logo)',
    author4:
      'Jesús Hernández, Mónica Rivera, Andrés Álvarez, Juan Suárez, María Vanegas, Lina Cuellar, Victoria Romero, Estefanía Ballesteros, Mariá C. Pulido, Hugo Quintero, Diana Acuña, René Segura, Wilson Carreño, Luisa Lozano, Daniel Ortiz, Rowena Neme, Lorena Mejía, Laura Varón, Miguel Vallejo, Diana Sarasti, Amalia Satizábal, Andrea Gaitán, Juan Duarte, Pavel Molano, Pedro Rivera, Verónika Cháves, Sergio Rodríguez, Daniel C. Pineda (Ilustradores)',
  },
  {
    title: '70 kilogramos',
    year: 2021,
    author: 'Byron Alaff (Autor)',
  },
  {
    title: 'A Freddie',
    year: 1993,
    author: 'Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'A Silvina',
    year: 1995,
    author: 'Diego Guerra (Autor)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'A Sweet Peek',
    year: 2016,
    author: 'Silvis Sanabria (Autora)',
  },
  {
    title: 'A...pagón A...pagar',
    year: 1992,
    author: 'Chócolo',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Abracadabra (Soy una contradicción que respira: Capítulo primero)',
    year: 1993,
    author: 'Quiló',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'Abrecartas',
    year: 2010,
    author: 'Berliac (Sofía Bergé) (Ilustradora)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Absolut comics',
    year: 1993,
    author2: 'Bernardo Rincón (director)',
    author3: 'Giovanni Castro(portada)',
  },
  {
    title: 'Acabavacadabra: capítulo final',
    year: 1995,
    author: 'Quiló',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'Acmerreir',
    year: 1992,
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'Adan Reus',
    year: 2022,
    author: 'Andrés Urán (Guión, Dibujo)',
    author2: 'Fernanda Jaramillo (Editora)',
  },
  {
    title: 'Adán tomó la manzana',
    year: 1984,
    author: 'Jorge Saavedra',
    author2: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Adentro',
    year: 1995,
    author: 'Miguel Villada',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Adrián: una aventura en busca de un aventurero',
    year: 2021,
    author: 'Daniel Ordoñez (Autor)',
    author2: 'Manuela Quintero (Escritora)',
  },
  {
    title: 'Afernalum',
    year: 2005,
    author: 'Luis Suárez (Ilustrador)',
    author2: 'Andrés Obregón (guión)',
  },
  {
    title: 'Agencia tres dedos',
    year: 1994,
    author: 'Bernardo Rincón',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Agente Malintz',
    year: 2022,
    author: 'Rodrigo Lucio (Othro Boros) (guión)',
    author2: 'Gusanillo (Ilustrador)',
  },
  {
    title: 'Ahora una de elfos',
    year: 1996,
    author: 'Eneas (texto e ilustraciones)',
  },
  {
    title: 'Ahora yo quiero ser el dragón',
    year: 2018,
    author: 'Altais',
  },
  {
    title: 'Alberto',
    year: 1992,
    author: 'Alberto',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Algea',
    year: 2020,
    author: 'Thomas Oni-Sama (Autor)',
  },
  {
    title: 'Alimañas Peligrosas',
    year: 2022,
    author: 'Luisa F. Rojas (Ilustradora)',
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'ALyEN',
    year: 2008,
    author: 'Miguel Osorio Valderrama',
    author2: 'Martin Osorio Valderrama',
  },
  {
    title: 'Amacise',
    year: 1952,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'Anacrónica: la chica atemporal',
    year: 2014,
    author: 'Daniel Greiff',
  },
  {
    title: 'Anarquito',
    year: 1998,
    author: 'Warner Wallis',
  },
  {
    title: 'Aníbal',
    year: 1993,
    author: 'Juancho',
    author2: 'Diego Tobón',
    author3: 'Flakoz',
    author4: 'Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'Animal de Ciudad',
    year: 2013,
    author: 'Erzebet Art (Artista)',
    author2: 'Rodrigo Lucio (Othro Boros) (Escritor)',
  },
  {
    title: 'Annoying',
    year: 2018,
    author: 'Demonbloodpal (DBP)',
  },
  {
    title: 'Antología Gorgona',
    year: 2021,
    author: 'Rodrigo Lucio (Othro Boros) (Guionista)',
    author2: 'Carlos Dearmas (Dibujante)',
    author3: 'Gusanillo (Dibujante)',
  },
  {
    title: 'Aprenda a dibujar',
    year: 1973,
    author: 'Desconocido',
  },
  {
    title: 'Apropiaciones Justo y Framco y Calvin & Hobbes',
    year: 1991,
    author: 'Jorge Grosso',
  },
  {
    title: 'Apuntes picantes de Lorac Moure',
    year: 1993,
    author: 'Caramelot',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'Apuntes picantes de Lorac Moure: Segunda parte',
    year: 1994,
    author: 'Caramelot',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Arka',
    year: 1999,
  },
  {
    title: 'Armonia',
    year: 2022,
    author: 'Kevin Correa (Historia)',
    author2: 'Aly (Dibujos)',
  },
  {
    title: 'Arqueonautas',
    year: 2016,
    author: 'Fernando Suárez Buitrago',
  },
  {
    title: 'Arquetipo',
    year: 2008,
  },
  {
    title: 'Arrancando el corazón de una dama',
    year: 2009,
    author: 'Joni B',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Daniel Jiménez Quiroz (editor), Mauricio Giraldo, Juan Francisco Jaramillo (equipo editorial), José Raúl Jaramillo (corrección)',
  },
  {
    title: 'Arriba',
    year: 1995,
    author: 'Kaparó',
    author2: 'Bernardo Rincón (director)',
    author3: 'Marco Pinto (portada)',
  },
  {
    title: 'Arte aparte',
    year: 1995,
    author: 'Gova',
    author2: 'Elliott Sabogal',
    author3: 'Reimert',
  },
  {
    title: 'Arte conceptual',
    year: 1980,
    author: 'Jorge Saavedra',
    author2: 'Hans Anderegg',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Así somos',
    year: 1945,
    author: 'Chapete (Hernando Turriago Riaño)',
  },
  {
    title: 'Aspid',
    year: 1994,
    author: 'Serna - William Rusinque Alvarán',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Aspiraciones para ser dueño del mundo',
    year: 1992,
    author: 'Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'Atila',
    year: 1989,
    author: 'Jorge Grosso',
    author2: 'Quir',
    author3: 'Ceci',
    author4: 'Calero, Nicholls, Roa, Rincón',
  },
  {
    title: 'Aureus Graphers',
    year: 2014,
    author: 'Suzaku',
  },
  {
    title: 'Avacadabra Capítulo 5',
    year: 1994,
    author: 'Quiló',
    author2: 'Pepe & Caramelot (portada)',
    author4:
      'Gilles Fauveau (editor), Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Avatar No.1',
    year: 2006,
    author: 'Juan David Machado',
    author2: 'Eliana Santamaría',
  },
  {
    title: 'Avatar No.2',
    year: 2006,
    author: 'Juan David Machado',
    author2: 'Eliana Santamaría',
  },
  {
    title: 'Aventuras de Bambuco',
    year: 1933,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'Avracadabra Capítulo 2 1/2: (preludio a la moscarnicería)',
    year: 1994,
    author: 'Quiló',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Avracadabra capítulo 5',
    year: 1995,
    author: 'Quiló',
    author2: 'Nigio (portada)',
    author4:
      'Gilles Fauveau (editor), Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Avracadabra: Capítulo 2',
    year: 1994,
    author: 'Quiló',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Babilonia 2050',
    year: 1996,
    author: 'Keco Olano',
    author2: 'José Campo H. (director)',
    author4: 'José Campo H.(Portada), Fernando Suárez (contraportada)',
  },
  {
    title: 'Bachem 350 "Satellit"',
    year: 1996,
    author: 'Rodríguez (Argentina) (dibujos)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
    author4: 'Barreiro (guión)',
  },
  {
    title: 'Balita la bala perdida & Sue',
    year: 1981,
    author: 'León Octavio',
  },
  {
    title: 'Basta de basura occidental, viva el Talibán',
    year: 2009,
    author: 'Ernan Cirianni',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración Daniel Jiménez Quiroz (editor)',
  },
  {
    title: 'Bastonazos de ciego',
    year: 2008,
    author: 'Andrezzinho',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial). Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Basuras',
    year: 2021,
    author: 'Gusanillo',
  },
  {
    title: 'Bendita',
    year: 1999,
    author: 'Leo & Johan',
    author2: 'Gaiden',
    author3: 'Veins',
  },
  {
    title: 'Bendita',
    year: 2000,
    author: 'Leo & Johan',
    author2: 'Gaiden',
    author3: 'Veins',
  },
  {
    title: 'Bernardo Rincón',
    year: 1992,
    author: 'Bernardo Rincón',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title:
      'Besos Vasos Babas Bogocomics /Observaciones sobre la paciencia de la selva',
    year: 1986,
    author: 'Ángel Becassino (guiones)',
    author4:
      'Felipe Dothée, Nicolás Lozano, Carlos Santa, Juan Manuel Lugo, Víctor Sánchez, Álvaro Moreno, Mario Quintero, Alejandra Miranda, Diego Herrera, Nicholls',
  },
  {
    title: 'Bestiario Muysca',
    year: 2019,
    author: 'Karmao (Carlos Mauricio Palacios Soto) (Autor)',
    author2: 'Carolina Arango',
    author3: 'Paola A. Duque (corrección de estilo)',
  },
  {
    title: 'Blog HP',
    year: 2008,
  },
  {
    title: 'Bogotá Cómics No. 2 (segunda edición)',
    year: 2017,
    author: 'David García (Ilustrador)',
    author2: 'Alejandro Giraldo (portada)',
    author3: 'Johann García (director editorial)',
    author4: 'Alejandra Ortega (corrección de estilo)',
  },
  {
    title: 'Bogotá Masacre ZOMBIE',
    year: 2012,
    author: 'Juan Pablo Elías Silva',
  },
  {
    title: 'Bogotá y Venganza Vol 1',
    year: 2021,
    author: 'Art Jhonson',
    author2: 'DerTod',
    author3: 'Gusanillo',
    author4:
      'Fabricio RivasMar, CGrafica, Iván Moreno, Andrés Cruz Barrera, Cesar Saba, Lu Artistika, Mateo Ramírez',
  },
  {
    title: 'Bolívar',
    year: 1983,
    author: 'Hermanos Sierra',
  },
  {
    title: 'Bonnie & Clyde',
    year: 1995,
    author: 'Barreiro',
    author2: "D'Angiolillo & Sanabria",
    author3: 'Brügo (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Bribona',
    year: 2020,
    author: 'Ram Shirley',
  },
  {
    title: 'Burladero',
    year: 1995,
    author: 'Giova',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'C/Dos/run',
    year: 2013,
    author: 'Nicolás Salamanca Malagón (Autor)',
    author2: 'J. Andrey Portilla (portada)',
  },
  {
    title: 'Caballero',
    year: 1992,
    author: 'Antonio Caballero',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Cacique Tuchin',
    year: 1995,
    author: 'Carmelo',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Cada río se merece un vago',
    year: 1995,
    author: 'Orlando Martínez',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'Cal',
    year: 1992,
    author: 'Cal',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Calarcá',
    year: 1969,
    author: 'Carlos Garzón (Ilustrador)',
    author2: 'Jorge Peña',
  },
  {
    title: 'Calo I',
    year: 1992,
    author: 'Clemente',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Calo I',
    year: 1993,
    author: 'Clemente',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Caloi',
    year: 1992,
    author: 'Caloi',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Caloi',
    year: 1992,
    author: 'Caloi',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Calvin y Hommes',
    year: 1994,
    author: 'Santiago Hobbes, Tigrecillo de felpa (by Waterson)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Calypso',
    year: 1998,
    author: 'José Campo H.',
  },
  {
    title: 'Cambio y sustracción',
    year: 2011,
    author: 'Keco Olano (ilustraciones)',
    author2: 'Erizo (antonio García-Ángel guion)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño), Daniel Jiménez Quiroz (director)',
  },
  {
    title: 'Caminos Condenados',
    year: 2016,
    author: 'Pablo Guerra (Guionista)',
    author2: 'Henry Díaz (Dibujante)',
    author3: 'Camilo Aguirre (Dibujante)',
    author4:
      'Diana Ojeda (Investigadora); Jenny Petzl, Catalina Quiroga, Ana Catalina Rodriguez, Juan Guillermo Rojas (investigadores)',
  },
  {
    title: 'Carefuckyou v.s. El apocalipsis nazi',
    year: 2012,
    author: 'Luto',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Luto (portada)',
    author4: 'Jessica Garzón (diseño)',
  },
  {
    title: 'Carmelo',
    year: 1992,
    author: 'Carmelot',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Carmelo',
    year: 1993,
    author: 'Carmelot',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Carnibalito',
    year: 1995,
    author: 'Mymo',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'Carta dibujada a Edward Gorey',
    year: 2011,
    author: 'M. A. Noregna',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Cartones',
    year: 1972,
    author: 'Antonio Caballero (caricaturista)',
  },
  {
    title: 'Castillo de Frailes',
    year: 1975,
    author: 'Valverde',
  },
  {
    title: 'Castillo de Frailes',
    year: 1975,
    author: 'Valverde',
  },
  {
    title: 'Castillos en el aire',
    year: 1991,
    author: 'Albertising (Alberto Rodríguez)',
  },
  {
    title: 'Cédula',
    year: 1992,
    author: 'Naide',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Cerón',
    year: 1996,
    author: 'Cerón',
    author2: 'José Campo H.(Portada),',
    author3: 'Fernando Suárez (contraportada)',
    author4: 'José Campo H. (director)',
  },
  {
    title: 'Cesar',
    year: 1992,
    author: 'Gilab',
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'Cesar',
    year: 1993,
    author: 'Gilab',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'César',
    year: 1993,
    author: 'Gilalb',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'César',
    year: 1993,
    author: 'Gilalb',
    author2: 'Bernardo Rincón (director)',
    author3: 'Rodez (carátula)',
  },
  {
    title: 'César',
    year: 1993,
    author: 'Gilalb',
    author2: 'Bernardo Rincón (director)',
    author3: 'Giovanni Castro(portada)',
  },
  {
    title: 'César',
    year: 1994,
    author: 'Gilab',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'César',
    year: 1994,
    author: 'Gilalb',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'César',
    year: 1994,
    author: 'Gilalb',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'César',
    year: 1995,
    author: 'Gilalb',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'César',
    year: 1995,
    author: 'Gilles',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'César',
    year: 1995,
    author: 'Gilles & Constanza',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'Chance',
    year: 2010,
    author: 'Jhon Joven (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Chance',
    year: 2011,
    author: 'John Joven',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Chance Cap. 2',
    year: 2011,
    author: 'John Joven',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Charly',
    year: 1995,
    author: 'Bernardo Rincón',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Charly G',
    year: 1991,
    author: 'Bernardo Rincón',
  },
  {
    title: 'Chas (One shot)',
    year: 2013,
    author: 'Carlos Simbaqueva "Karlozdesign"',
  },
  {
    title: 'Chevy',
    year: 1993,
    author: 'Caramelot',
    author2: 'Bernardo Rincón (director)',
    author3: 'Rodez (carátula)',
  },
  {
    title: 'Chino Risso',
    year: 1994,
    author: 'Carmelot',
  },
  {
    title: 'Chino Risso',
    year: 1997,
    author: 'Carmelot',
  },
  {
    title: 'Chócolo en París',
    year: 1993,
    author: 'Chócolo',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada) Grosso (Concepto)',
  },
  {
    title: 'Chucha rabiosa: Mata a la rata',
    year: 2008,
    author: 'Luto',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial), Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Chumy Chumez',
    year: 1992,
    author: 'Chumy Chumez',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Cielo rojo',
    year: 2017,
    author: 'Felipe Ocampo Naranjo (Ilustrador)',
    author2: 'Francisco León Lenis (Ilustrador)',
    author3: 'Lynda Acosta Bedoya (Ilustradora)',
    author4:
      'Paula Pino López (Guionista e Investigadora), Javier Peña Ortega (Editor e Investigador), Paola Zambrano Velasco (Productora e Investigadora), Mónica Pelaez Montoya (Editora gráfica), Carlos Bastidas Zambrano (Guionista)',
  },
  {
    title: 'Ciertas cosas que valen la pena',
    year: 1994,
    author: 'Maxo (Max Milfort Blandón)',
    author2: 'Max Milfort Blandón (director creativo)',
    author3: 'Diego Andrés Cardona Castro (director creativo)',
    author4: 'Andrés y Vargas (editores)',
  },
  {
    title: 'Cirilo',
    year: 1964,
    author: 'Carlos Garzón (Ilustrador)',
    author2: 'Jorge Peña',
    author3: 'Nelson Ramírez',
    author4: 'Julio Jiménez',
  },
  {
    title: 'Ciudad perdida',
    year: 2019,
    author: 'Julián Moreno Ramírez (Ilustrador)',
    author2: 'Javier Peña-Ortega (guión)',
    author3: 'Javier Peña-Ortega (portada) Paula Pino (contracarátula)',
    author4:
      'Carlos Bastidas Zambrano (editor), Luisa Gustín Cabila (editora gráfica)',
  },
  {
    title: 'Ciudad sin puertas',
    year: 2004,
    author: 'Andrés"Xego" Segovia',
    author2: 'Andrés Kujabanti',
    author3: 'Diego Alejandro Hincapie',
    author4: 'Luis Gabriel Trejos',
  },
  {
    title: 'Cloynation',
    year: 1995,
    author: 'Alex, Gilles (argumrnto)',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo) El grupo de los 7 (guion)',
  },
  {
    title: 'Cloynation: El hijo de Frankenstein',
    year: 1995,
    author: 'Viktor',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Cloynation: Por ella',
    year: 1995,
    author: 'Miguel Villada',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Cloynation: Sin título',
    year: 1995,
    author: 'Caramelot',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Cloynation: Sin título',
    year: 1995,
    author: 'Nigio',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Clubman',
    year: 1972,
    author: 'Ugo Barti (Armando Buitrago)',
  },
  {
    title: 'Cocinol al 100%',
    year: 1994,
    author: 'Leocomix',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Cocinol al 100: segunda parte',
    year: 1995,
    author: 'Leocomix',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Comando verde',
    year: 1998,
    author: 'Hormiga (Carlos Gómez)',
  },
  {
    title: 'Comiclub',
    year: 1994,
    author: 'Dr. Acme',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Cómics de Películas (Tiras Cómicas)',
    year: 2020,
    author: 'Feno Mozo (Artista)',
    author2: 'El Suesk (Escritor)',
  },
  {
    title: 'Comixcidios',
    year: 1992,
    author: 'Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'Comixidios',
    year: 1992,
    author: 'Leocomix',
    author2: 'Jorge Grosso (director)',
    author4: 'Taller del humor Bernardo Rincón (coordinador)',
  },
  {
    title: 'Comixtriptis',
    year: 1993,
    author: 'Nany',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto) Fish, Carmelot, Cal, Palomo, Ecce homo',
  },
  {
    title: 'Cómo desaparecer a tu competencia (literalmente)',
    year: 2021,
    author: 'Feno Mozo (Autor)',
  },
  {
    title: 'Como Moscas',
    year: 2015,
    author: 'MIDI (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Comodidades de la vida en Chapinero',
    year: 1918,
    author: 'Pepe Gómez',
  },
  {
    title: 'Compay gato!',
    year: 1980,
    author: 'A. Martínez (dibujos)',
    author2: 'Hans Anderegg',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Condemnarum',
    year: 2017,
    author: 'Andrés Cruz Barrera',
  },
  {
    title: 'Confesión',
    year: 1994,
    author: 'Miller 90143',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Contamplación',
    year: 2012,
    author: 'Camilo Aguirre',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Luto (portada)',
    author4: 'Jessica Garzón (diseño)',
  },
  {
    title: 'Contra bandistas',
    year: 1984,
    author: 'Ricardo Potes (adaptación y dibujos)',
    author2: 'José Francisco Socarrás (Autor original)',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Contratos anulados (Bogotá)',
    year: 1993,
    author: 'Victor Hugo Velásquez (dibujos), y',
    author2: 'Sergio Álvarez (guión)',
    author3: 'Julio A. Rojas (Diseño) ',
    author4: 'Velásquez (dibujo portada), Jorge Grosso (director editorial)',
  },
  {
    title: 'Converts',
    year: 1989,
    author: 'Jorge Grosso',
    author2: 'Quir',
    author3: 'Ceci',
    author4: 'Calero, Nicholls, Roa, Rincón',
  },
  {
    title: 'Copetín',
    year: 1962,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Copetín',
    year: 1969,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Copetín',
    year: 1972,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Copetín',
    year: 1990,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Copetín',
    year: 1990,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Copetín',
    year: 2005,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Cortometrajes',
    year: 1993,
    author: 'Fosi',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Eusse, Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'Cortometrajes',
    year: 1993,
    author: 'Wil & Fosi',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'Cortometrajes',
    year: 1993,
    author: 'Tangarife',
    author2: 'Diego Tobón (director)',
    author3: 'Flakoz (portada)',
    author4:
      'Fosi, Wil, Capa, Tobón Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Cortosmetrajes',
    year: 1993,
    author: 'Flakoz',
    author2: 'París',
    author3: 'Juancho',
    author4: 'Diego Tobón, Flakoz, Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'Coyote',
    year: 2021,
    author: 'Rodachina (Autora)',
  },
  {
    title: 'Crasher',
    year: 1994,
    author: 'Jimeno',
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Crasher',
    year: 1995,
    author: 'Jimeno Farfán',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Crasher',
    year: 1995,
    author: 'Jimeno',
    author2: 'Bernardo Rincón (director)',
    author3: 'Marco Pinto (portada)',
  },
  {
    title: 'Crasher',
    year: 1996,
    author: 'Jimeno (Autor)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Criaturas peligrosas',
    year: 2023,
    author: 'Andrés Urán (Guion, dibujo)',
    author2: 'Fernanda Jaramillo (Editora)',
  },
  {
    title: 'Crónica',
    year: 1982,
    author: 'Marco Aurelio',
    author2: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Crónicas de Laundrim',
    year: 2020,
    author: 'Sergio Corradine (Autor)',
    author2: 'Mafer Lear (Colorista)',
  },
  {
    title: 'Crónicas Lutómanas: La requisa',
    year: 2009,
    author: 'Luto (Luis Eduardo Tobón)',
    author2: 'Sebastián Rubiano (Diseño)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración) Pedro josé Giraldo (director), Daniel Jiménez Quiroz (editor)',
  },
  {
    title: 'Cuaderno de viaje New York',
    year: 2010,
    author: 'Tatee',
    author2: 'Daniel Jiménez Quiroz (editor)',
    author3: 'Nobara Hayakawa (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración Pedro josé Giraldo, Mauricio Giraldo, Félix Salamanca, Hugo Ruiz (asistencia editorial) Paola Gaviria (editora invitada)',
  },
  {
    title: 'Cuando uno es rico',
    year: 1993,
    author: 'Lobo',
    author4: 'Diego Tobón, Flakoz, Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'Cuento de hadas',
    year: 1993,
    author: 'Velasquez & Alzate Jurado',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'Cuentos de San Victorino',
    year: 2018,
    author: 'Carlos Martínez',
  },
  {
    title: 'Cuentos de terror',
    year: 1995,
    author: 'Nelson 8',
    author3: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo)',
  },
  {
    title: 'Cuentos dibujados',
    year: 1991,
    author: 'Santiago Diaz',
  },
  {
    title: 'Cuestión de tiempo',
    year: 1994,
    author: 'Caramelot (dibujos)',
    author2: 'Alexander Velásquez (guion)',
    author3: 'Caramelot (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Cuestión de tiempo',
    year: 1994,
    author: 'Alex & Caramelot',
    author2: 'Pepe & Caramelot (portada)',
    author4:
      'Gilles Fauveau (editor), Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Dago',
    year: 1964,
    author: 'Carlos Garzón (Ilustrador)',
    author2: 'Jorge Peña',
    author3: 'Nelson Ramírez y Julio Jiménez',
  },
  {
    title: 'Dating Dick',
    year: 2022,
    author: 'John Googuins (Autor)',
  },
  {
    title: 'De bola a bola (crítica pictórica)',
    year: 1927,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'De domingo a domingo',
    year: 1969,
    author: 'Chapete (Hernando Turriago Riaño)',
  },
  {
    title: 'De humanos, buitres y otras alimañas',
    year: 1994,
    author: 'Giovanni Castro',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'De humanos, buitres y otras alimañas',
    year: 1994,
    author: 'Nigio (Ilustrador)',
    author2: 'Giovanni Castro (guion)',
    author3: 'Caramelot (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Decisiones',
    year: 1993,
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada) Grosso (Concepto)',
  },
  {
    title: 'Deimos',
    year: 1962,
    author: 'Carlos Cajiao',
    author2: 'Fernando Acosta',
  },
  {
    title: 'Deja-vú',
    year: 1999,
  },
  {
    title: 'Dejémonos de Fraude No.1-No.5',
    year: 2006,
    author: 'Pablo Guerra (el amigolucho)',
    author2: 'Camilo Sánchez (Camilovsky)',
    author3: 'Andrés Montealegre (Teléfono4)',
  },
  {
    title: 'Delirium',
    year: 2013,
    author: 'Julián Naranjo (dibujo y tintas)',
    author2: 'Helmann Salazar (historia y guión)',
    author3: 'Andrés Mossos (historia y guión)',
    author4: 'J. Andrey Portilla (portada, color y diseño)',
  },
  {
    title: 'Demasiado Juntos',
    year: 2020,
    author: 'Minina (Guión y Color)',
    author2: 'Minifox (Guión e Ilustración)',
  },
  {
    title: 'Deposición comic',
    year: 1998,
  },
  {
    title: 'Desde lo Profundo del Vacío',
    year: 2015,
    author: 'Julián Naranjo',
    author2: 'Othro Boros',
  },
  {
    title: 'Desde Nueva York',
    year: 1948,
    author: 'Chapete (Hernando Turriago Riaño)',
  },
  {
    title: 'Despedido',
    year: 2013,
    author: 'Feno Mozo (Autor)',
  },
  {
    title: 'Despresador',
    year: 1993,
    author: 'Eusse',
    author4: 'Diego Tobón, Flakoz, Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'Después de la escuela',
    year: 2008,
    author: 'Truchafrita (Álvaro Vélez)',
    author2: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial),  Pedro josé Giraldo (director), Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'D-fin (y ciones)',
    year: 1994,
    author: 'Bernardo Rincón',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Diamanda Diamante',
    year: 2018,
    author: 'Diego Rojas (portada)',
    author2: 'Albertising (diseño)',
    author3: 'No (textos)',
    author4:
      'Albertising, Daniela Daza, Daniela Pinilla, David Ruiz, Leandro Ruiz, Lola Bigotes, Diego Miguel Rojas, Douglas de la Hoz, Nigio (Giovanni Castro), Jorge Ávila, María Arteaga, María Isabel Corredor, Beladika, No para Innita, Pilar Berrío (Ilustradores)',
  },
  {
    title: 'Diecisiete ochenta',
    year: 1992,
    author: 'Caramelot (dibujos)',
    author2: 'No (guión)',
    author3: 'Leocomix (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Diecisiete ochenta',
    year: 1993,
    author: 'Caramelot',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'Dilan - Las Crónicas de Covak',
    year: 2018,
    author: 'Daniel José Penagos (Ilustrador)',
    author2: 'Oscar Torres (Editor)',
    author3: 'Sebastian Amaya (Letras)',
  },
  {
    title: 'Dimensia',
    year: 2001,
    author: 'Mauricio Pinzón',
  },
  {
    title: 'Dina',
    year: 1988,
    author: 'Bernardo Rincón',
  },
  {
    title: 'Dina',
    year: 1991,
    author: 'Bernardo Rincón',
  },
  {
    title: 'Dina',
    year: 1995,
    author: 'Bernardo Rincón',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Distrito capital',
    year: 1993,
    author: 'Flakoz, Bernardo Rincón, Santiago, Quiló y Leocomix',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Doctor',
    year: 1993,
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada) Grosso (Concepto)',
  },
  {
    title: 'Don Amacise',
    year: 1944,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'Don Basman - El Caballero de la Capa Raída',
    year: 2010,
    author: 'Camilo Andrés Triana Cubillos (Guión y dibujo)',
    author2: 'Giovanni Nieto "GioWolf" (Colorista)',
    author3: 'Henry Díaz (Rotulación)',
  },
  {
    title: 'Don Luigi',
    year: 1992,
    author: 'Laerte',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Doomsday blues',
    year: 1993,
    author: 'Quiló',
    author2: 'Bernardo Rincón (director)',
    author3: 'Rodez (carátula)',
  },
  {
    title: 'Doppler',
    year: 2012,
    author: 'Enrique Uribe Jongbloëd (Escritor)',
    author2: 'Adrián Castilla (Dibujos)',
    author3: 'Alberto Luna (Color)',
    author4: 'El Suesk (Animador)',
  },
  {
    title: 'Dos Aldos',
    year: 2016,
    author: 'Pablo Guerra (Guionista)',
    author2: 'Henry Díaz (Dibujante)',
  },
  {
    title: 'Dr. Jaque y Sr. Mate',
    year: 1991,
    author: 'Grosso',
    author4:
      'Grosso, Sanabria, Quilo, Caramelot, Leocomix, Rincón, Santiago Diaz',
  },
  {
    title: 'Dr. Jaque y Sr. Mate',
    year: 1992,
    author: 'Quiló',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Drake',
    year: 1994,
    author: 'Pepe Peña',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Drake',
    year: 1994,
    author: 'Pepe',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Drake',
    year: 1995,
    author: 'Pepe',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Drake Segunda parte',
    year: 1994,
    author: 'Pepe Peña',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Eco-lógicas',
    year: 1996,
    author: 'Perucho',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Editoral',
    year: 1992,
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Educación sentimental',
    year: 1995,
    author: 'Diego Guerra (Autor)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Marco Pinto (portada)',
  },
  {
    title: 'Educación sentimental',
    year: 1996,
    author: 'Diego Guerra (Autor)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Educación sentimental',
    year: 1996,
    author: 'Diego Guerra (Autor)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'El (H)acha',
    year: 2011,
    author: 'Luis Echavarría (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'El agrnte ZZ7',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'El árbol de las cuatro raíces',
    year: 2021,
    author: 'Taller Creativo de Aleida Sánchez B. SAS',
    author2:
      '.PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
    author3: 'Comisión de la Verdad',
    author4:
      'Aleida Sánchez Buitrago (Coordinación editorial y producción general) Lorenzo Camacho Téllez y Jorge Camacho Velásquez (guión) Mario Urazán Roncancio (Diseño y diagramación) Aleida Sánchez Buitrago y Zamara Zambrano Sánchez (Ilusttración)',
  },
  {
    title: 'El Astrólogo',
    year: 2020,
    author: 'Daniel Hincapié (Ilustrador)',
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'El Atardecer de los Cables',
    year: 2017,
    author: 'Natalia Duque - Yura Mate',
  },
  {
    title: 'El baile de San Pascual',
    year: 2019,
    author: 'Camilo Vieco (Autor)',
  },
  {
    title: 'El bus chepito',
    year: 1992,
    author: 'Grosso',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: "El Cerra'o. Comienzos",
    year: 2019,
    author: 'Julio César Rodríguez',
  },
  {
    title: 'El chiqui',
    year: 1995,
    author: 'Nani',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'El comic y el arte secuencial',
    year: 2008,
    author: 'Mr. Z',
    author2: 'Pedro josé Giraldo (director),',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'El corazón delator',
    year: 1984,
    author: 'Wilson R. (ilustraciones)',
    author2: 'Adaptación de Edgar Allan Poe',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'El cuentico amarillo 2019',
    year: 2019,
    author: 'Pablo Pérez (Altais)',
  },
  {
    title: 'El curandero',
    year: 2019,
    author: 'Paula Pino (ilustración)',
    author2: 'Javier Peña-Ortega (guión y portada)',
    author3: 'Paula Pino (contracarátula)',
    author4:
      'Carlos Bastidas Zambrano (editor), Luisa Gustín Cabila (editora gráfica)',
  },
  {
    title: 'El Desierto y la Silueta',
    year: 2013,
    author: 'Tatania Ríos (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'El Día del Pentecostés',
    year: 2013,
    author: 'Diego Zhakén',
  },
  {
    title: 'El Diario de Emmanuel',
    year: 2017,
    author: 'Edier Becerra',
  },
  {
    title: 'El difícil oficio de perdonar',
    year: 2019,
    author: 'Andrew Maltés (Artista)',
    author2: 'Federico Soto (Escritor)',
    author3: 'Zurzoif (Diseñador)',
  },
  {
    title: 'El drake',
    year: 2004,
    author: 'Pablo Guerra',
    author2: 'Camilo Sánchez',
  },
  {
    title: 'El Empanadero del Futuro',
    year: 2020,
    author: 'Feno Mozo (Ilustrador)',
    author2: 'El Suesk (Escritor)',
  },
  {
    title: 'El espejo mágico',
    year: 1982,
    author: 'J. Wilson',
    author2: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'El Fin de la Humanidad',
    year: 2015,
    author: 'Julián Naranjo (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'El fin del robot anómalo',
    year: 2010,
    author: 'Kwaichang Kráneo (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño) Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'El gato',
    year: 1995,
    author: 'Daniel Rabanal (Autor)',
  },
  {
    title: 'El gozque moribundo de José Asunción Silva',
    year: 2013,
    author: 'Altais (Adaptación gráfica)',
    author2: 'José Asunción Silva (Texto original)',
  },
  {
    title: 'El hilo',
    year: 1995,
    author: 'Ángela Hernandez (guión)',
    author2: 'Constanza Tovar (dibujos)',
    author3: 'Leocomix (portada)',
    author4:
      'Gilles Fauveau (editor), Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo) Alberto Rossini T. (acabados)',
  },
  {
    title: 'El Incidente Naranja',
    year: 2015,
    author: 'Diego Zhakén',
  },
  {
    title: 'El increible Homo Pater',
    year: 2005,
    author: 'Rodolfo León Sánchez',
  },
  {
    title: 'El inferior',
    year: 1993,
    author: 'Wil',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'El inspector',
    year: 1994,
    author: 'Oscar Salazar Ruiz',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'El magistrado',
    year: 1995,
    author: 'Hallaco',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'El mohán',
    year: 1994,
    author: 'Calarcá',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'El momento fatal: una entrevista a Joe Sacco',
    year: 2018,
    author: 'Pablo Pérez (Altais)',
  },
  {
    title: 'El necio',
    year: 2002,
    author: 'Álvaro y P2',
  },
  {
    title: 'El Niño de la Aldea y la mágica isla',
    year: 2019,
    author: 'Rick Rodrick',
    author2: 'Rick Rodrick',
  },
  {
    title: 'El niño feo',
    year: 1994,
    author: 'Nigio & Asimov',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'El niño feo',
    year: 1995,
    author: 'Nigio (dibujos), Asimov (guion)',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'El niño feo',
    year: 1995,
    author: 'Nigio & Asimov',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'El noctiche',
    year: 1987,
    author: 'Jorge Baquero',
    author2: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'El oficio es duro y farragoso',
    year: 2010,
    author: 'Diego Guerra (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'El origen de Ra',
    year: 1995,
    author: 'Quique',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'El Oro es Silencio',
    year: 2015,
    author: 'Camilo Aguirre (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'El Otoño de los Héroes',
    year: 2023,
    author: 'Daniel Penagos (Escritor)',
    author2: 'Nicete (Ilustrador)',
    author3: 'Oscar torres (Editor)',
    author4: 'Sebastian Amaya (Diseñador)',
  },
  {
    title: 'El paseo se acabó',
    year: 2010,
    author: 'Luis Echavarría (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'El patrono',
    year: 2014,
    author: 'Inu Waters',
  },
  {
    title: 'El perro',
    year: 2008,
    author: 'Carlos García',
  },
  {
    title: 'El pez',
    year: 1982,
    author: 'Jorge Saavedra (basado en la novela El pez)',
    author2: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
    author3: 'Ricardo Potes (coordinador)',
  },
  {
    title: 'El Piojo',
    year: 2008,
    author: 'Antonio Villaloboso',
  },
  {
    title: 'El plato fuerte',
    year: 1994,
    author: 'Maxo (Max Milfort Blandón)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)  Andrés y Vargas (editores)',
  },
  {
    title:
      'El problema no está en la luz sino en los Luciferes que nos gobiernan',
    year: 1992,
    author: 'Caballero',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'El Reciclador',
    year: 2019,
    author: 'Mateo Nieto',
  },
  {
    title: 'El robo',
    year: 1993,
    author: 'Maxo (Max Milfort Blandón)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos), Andrés y Vargas (editores)',
  },
  {
    title: 'El robot anómalo',
    year: 2010,
    author: 'Kwaichang Kráneo (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'El Salado',
    year: 2018,
    author: 'José Luis Jiménez',
    author2: 'Miguel Jiménez',
  },
  {
    title: 'El saqueo',
    year: 2019,
    author: 'Andrés Ocampo (ilustador)',
    author2: 'Paula Pino (guión)',
    author3: 'Javier Peña-Ortega (portada)',
    author4:
      'Carlos Bastidas Zambrano (editor), Luisa Gustín Cabila (editora gráfica) Paula Pino (contracarátula)',
  },
  {
    title: 'El séptimo sello',
    year: 1994,
    author: 'Alejandro Rubio R. (Ilustraciones)',
    author2: 'Carlos E. Vesga (Ilustraciones)',
    author3: 'Adaptación texto Biblia de Jerusalem edición española',
    author4:
      'Federmán Contreras Díaz (director), Diego Sarmiento Herrera (Editor) Alejandro Rubio (portada)',
  },
  {
    title: 'El signo del traidor',
    year: 1997,
    author: 'Viktor',
    author2: 'Bernardo Rincón (director)',
    author3: 'Alejandro Gutiérrez (portada) (México)',
  },
  {
    title: 'El sueño de las serpientes',
    year: 1995,
    author: 'Diego Guerra',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'El tatuaje',
    year: 2011,
    author: 'Jim Pluk',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'El Taxista llama dos veces',
    year: 2017,
    author: 'Keco Olano (Ilustrador)',
    author2: 'Antonio Garcia-Ángel (guion)',
    author3: 'Juan Carlos Rodríguez (guion)',
    author4: 'Heidi Muskus (matte paint, maquetación)',
  },
  {
    title: 'El Templo de Bacatá',
    year: 2023,
    author: 'Eliana Stefanny Gomez Tello "Eliana55226838"',
  },
  {
    title: 'El teniente colombino',
    year: 1975,
    author: 'Valverde',
  },
  {
    title: 'El traje de novia',
    year: 1996,
    author: 'Dora Alonso & Victor Cordero',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'El tren ya no está nunca',
    year: 1993,
    author: 'Giovanni Castro (Autor)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'El Último Aliento',
    year: 2008,
    author: 'Jhonattan Balcázar',
  },
  {
    title: 'El último que gobierna apaga la luz',
    year: 1992,
    author: 'Grosso',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'El último rollo del mar muero',
    year: 1994,
    author: 'Gilles & Quiló',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'El valle del amor',
    year: 1994,
    author: "Barreiro, D'angiolillo & Sanabria",
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'El viaje del Cárabo',
    year: 2013,
    author: 'J. Andrey Portilla (Autor)',
    author3: 'J. Andrey Portilla (portada)',
  },
  {
    title: 'El Western de Neptuno',
    year: 2019,
    author: 'Ricardo Moreno "Rick761" (Escritor y Ilustrador)',
  },
  {
    title: 'Elefantes en el cuarto',
    year: 2016,
    author: 'Sindy Elefante (Ilustradora y Escritora)',
  },
  {
    title: 'Elemental',
    year: 2021,
    author: 'Jorge Velásquez (Artista)',
    author2: 'Gapex Gamora (Escritor)',
    author3: 'Said Atala (Color)',
    author4: 'Topbox (Portada)',
  },
  {
    title: 'Elisa',
    year: 1995,
    author: 'Bernardo Rincón',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Ellos bailaron una danza',
    year: 1994,
    author: 'Guerrero',
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Emilia',
    year: 2019,
    author: 'Lina Flórez G., Pablo Pérez',
  },
  {
    title: 'Emma Zunz',
    year: 1996,
    author: 'Dibujos (Daniel Rabanal)',
    author2: 'Jorge Luis Borges (guión)',
    author3: 'Bernardo Rincón (director)',
    author4: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Empanadaman',
    year: 2013,
    author: 'Julián Naranjo (Autor)',
  },
  {
    title: 'Emús en la zona',
    year: 2019,
    author: 'Rey Migas (Mario Garzón)',
  },
  {
    title: 'En lo Profundo del Bosque: Camino a la puerta dorada',
    year: 2018,
    author: 'Sergio Corradine',
  },
  {
    title: 'En lo Profundo del Bosque: En Busca del Cristal del Cielo',
    year: 2017,
    author: 'Sergio Corradine',
  },
  {
    title: 'En pos del coño desbocado',
    year: 1995,
    author: 'Abulí (España)',
    author2: 'Darko (Yugoslavia)',
    author3: 'Marco Pinto (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Encuentro de dos mundos',
    year: 1993,
    author: 'Eccehomo',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Enor',
    year: 1982,
    author: 'Bernardo Ríos (historietista)',
    author2: 'Jorge Peña',
  },
  {
    title: 'En-soñacion',
    year: 1993,
    author: 'Fish',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Entonces fue amor',
    year: 1993,
    author: 'Cecilia Cáceres',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'Entre el Cielo y el Infierno',
    year: 2007,
    author: 'Altais',
  },
  {
    title: 'Entre Las Sombras',
    year: 2017,
    author: 'Mateo Ramírez (Colaborador)',
    author2: 'Ficciorama (Colaborador)',
    author3: 'Gorrión Blanco (Colaborador)',
    author4:
      'Rafael Bonilla (Colaborador), J. Tejedor (Colaborador), Paola Sierra (Colaborador)',
  },
  {
    title: 'Espectaculares héroes colombianos',
    year: 2018,
    author: 'Juan Pablo Silva (Autor)',
    author2: 'Alexander Cendales (asidtencia en color)',
    author3: 'María del Socorro Elías (corrección de textos)',
  },
  {
    title: 'Espectaculares Héroes Colombianos: 1948',
    year: 2019,
    author: 'Juan Pablo Elías Silva',
  },
  {
    title: 'Espectaculares Héroes Colombianos: Kid',
    year: 2019,
    author: 'Juan Pablo Elías Silva',
  },
  {
    title: 'Espectaculares Héroes Colombianos: Protector',
    year: 2019,
    author: 'Juan Pablo Elías Silva',
  },
  {
    title: 'Esrokanrol en la ciudad',
    year: 2010,
    author: 'Luto (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Están entre nosotros',
    year: 2019,
    author: 'Juan David Peñaranda (Ilustrador)',
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'Estrella',
    year: 2018,
    author: 'Juan David Montoya (Escritor)',
    author2: 'Altais (Artista)',
  },
  {
    title: 'Euri, El Ultimo Llamado',
    year: 2022,
    author: 'Hernando Diaz (Escritor)',
    author2: 'Wilton Castro (Lápices y Tinta)',
    author3: 'Alejandro Salas (Color)',
  },
  {
    title: 'Eventos',
    year: 1994,
    author: 'Ricardo Medina',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Evohumanos',
    year: 2018,
    author: 'Catman (Ilustrador y Escritor)',
  },
  {
    title: 'Exo-Gen',
    year: 2012,
    author: 'Champe (Ilustrador y Escritor)',
  },
  {
    title: 'Explorer Cats',
    year: 2021,
    author: 'Fagua_Art',
  },
  {
    title: 'Fabio Zerpa tiene razón',
    year: 2018,
    author: 'Andrés Urán (Dibujante)',
  },
  {
    title: 'Falom',
    year: 2008,
    author: 'Jaime Alexander Roa (guión)',
    author2: 'Hernando Díaz (ilustraciones)',
    author3: 'Camilo Tapias (ilustraciones)',
    author4: 'Andres Rey (Ilustradores)',
  },
  {
    title: 'Fanzin Fanzón',
    year: 1995,
    author: 'Leonardo Gama Benzeno',
  },
  {
    title: 'Farmacia',
    year: 2008,
    author: 'Joni B',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Feliz Navidad',
    year: 1993,
    author: 'Sanabria',
    author2: 'Jorge Grosso (director editorial y concepto)',
    author3: 'Velásquez (dibujo portada)',
  },
  {
    title: 'Fernández',
    year: 1995,
    author: 'Fernández',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo)',
  },
  {
    title: 'Ferney',
    year: 1992,
    author: 'Ferney',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Fikon',
    year: 2003,
    author: 'Fernando Gutiérrez',
    author2: 'Luis Henry Vanegas Orozco',
  },
  {
    title: 'Forgasm',
    year: 2001,
    author: 'John Escher',
  },
  {
    title: 'Fragmentado',
    year: 2022,
    author: 'ArtomicDesign (Autora, Ilustradora)',
    author2: 'Carlos Alfredo Tesillo (Editor)',
  },
  {
    title: 'Fué',
    year: 1994,
    author: 'Brügo',
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Fuerzas Extrañas',
    year: 1985,
    author: 'José Juliao',
    author2: 'Armando Rosales',
  },
  {
    title: 'Gabo *memorias de una vida mágica*',
    year: 2013,
    author: 'Miguel Bustos (ilustraciones)',
    author2: 'Tatiana Córdoba (ilustraciones)',
    author3: 'Oscar Pantoja (guion)',
    author4:
      'Felipe Camargo Rojas, Julián Naranjo (ilustraciones César Garzón(diseño editorial y colorización) Rúl Castro Zea (diseño carátula) Carolina Rey y John Naranjo (dirección editorial) Luisa Martínez (colorización y asistencia gráfica) Adriana Rodríguez (corrección de estilo)',
  },
  {
    title: 'Gaiden',
    year: 2005,
    author: 'Iván David Saboya (Ilustrador)',
  },
  {
    title: 'Gallina ciega',
    year: 1996,
    author: 'José Campo H.',
    author2: 'José Campo H.(Portada)',
    author3: 'Fernando Suárez (contraportada)',
    author4: 'José Campo H. (director)',
  },
  {
    title: 'Gatho',
    year: 2008,
    author: 'Wil (William Zapata)',
    author2: 'Pedro josé Giraldo (director',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Gatho',
    year: 2009,
    author: 'Wil (William Zapata)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contraportada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración Daniel Jiménez Quiroz (editor)',
  },
  {
    title: 'Gato',
    year: 1993,
    author: 'Wil & JFM',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'Gato',
    year: 1993,
    author: 'Wil',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Gatotiki parte 4',
    year: 2010,
    author: 'Wil (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño) Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Generaciones que no se rinden',
    year: 2021,
    author: 'Saúl Franco Agudelo (Concepto y dirección)  ',
    author2: '.PUNTOAPARTE EDITORES Dylan Quintero (Ilustración)',
    author4:
      'Comisión de la Verdad Jeisson Reyes (Diagramación) Alejandro Valencia Villa (Comisionado de la Comisión de la Verdad)  Marta Ruiz Naranjo ((Comisionado de la Comisión de la Verdad) Pavel Santodomingo Aguilar (Comisionada de la Comisión de la Verdad) Bibiana Mercado Rivera (Objetivo de Reconocimiento de la Comisión de la Verdad) Lisa Neisa (Directora ClickArte S. A. S.) Emmanuel Neisa (Director ClickArte S. A. S.)',
  },
  {
    title: 'Génesis - La creación del universo',
    year: 1984,
    author: 'León Octavio',
  },
  {
    title: 'Génesis II',
    year: 1996,
    author: 'Oscar Sierra',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Gente muerta',
    year: 1992,
    author: 'Quiló',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Gilles & comix',
    year: 1993,
    author: 'Gilalb',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'Godofredo Cascarrabias',
    year: 1944,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'Gólgota',
    year: 2022,
    author: 'Nigio (Ilustrador)',
    author2: 'Albertising (Ilustrador)',
    author3: 'Ricardo Burgos (Escritor)',
  },
  {
    title: 'Gotas de tinta',
    year: 2008,
    author: 'Luis Tejada & Mr. Z',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Gotas de tinta',
    year: 2009,
    author: 'Maucho (Mauricio Giraldo)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración Daniel Jiménez Quiroz (editor)',
  },
  {
    title: 'Greed',
    year: 2013,
    author: 'Eric "Rimutoki Pavach"',
  },
  {
    title: 'Grosso',
    year: 1992,
    author: 'Grosso',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Grosso',
    year: 1991,
    author4:
      'Grosso, Sanabria, Quilo, Caramelot, Leocomix, Rincón, Santiago Diaz',
  },
  {
    title: 'Guillotina',
    year: 2002,
    author: 'Taller de profundización de narración gráfica',
  },
  {
    title: 'Habana 1944',
    year: 1996,
    author: 'Sanabria (dibujos)',
    author2: 'Barreiro (guión)',
    author3: 'Bernardo Rincón (director)',
    author4: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Habana 1944',
    year: 1996,
    author: 'Sanabria (dibujos)',
    author2: 'Barreiro (guión)',
    author3: 'Bernardo Rincón (director)',
    author4: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Habana 1944',
    year: 1997,
    author: 'Sanabria (dibujos)',
    author2: 'Barreiro (guión)',
    author3: 'Bernardo Rincón (director)',
    author4: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Habilidades cotidianas',
    year: 1984,
    author: 'Rigo (arte)',
    author2: 'Felipe Ossa (historia)',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Hasta que honremos la vida',
    year: 2021,
    author: 'Dylan Quintero (Ilustraciones)',
    author2: 'Jeisson Reyes (Diagramación)',
    author4:
      'Comisión de la Verdad Alejandro Valencia Villa Comisionado de la Comisión de la Verdad Marta Ruiz Naranjo Comisionada de la Comisión de la Verdad Pavel Santodomingo Aguilar Bibiana Mercado Rivera Objetivo de Reconocimiento de la Comisión de la Verdad Lisa Neisa Directora ClickArte S. A. S. Emmanuel Neisa Director ClickArte S. A. S.',
  },
  {
    title: 'Heavenly Breach',
    year: 2017,
    author: 'Steferick',
  },
  {
    title: 'Hicotea',
    year: 2019,
    author: 'Lorena Álvarez (Autora)',
    author2: 'Gonzalo Quesada (traducción)',
    author3: 'Ana González de la Peña (rotulación)',
    author4: 'Alba Diethelm (maquetación)',
  },
  {
    title: 'Historia natural',
    year: 1993,
    author: 'Giovanni Castro (ilustraciones)',
    author2: 'Gilles Fauveau (guión)',
    author3: 'Bernardo Rincón (portada y dirección)',
  },
  {
    title: 'Historias cotidianas',
    year: 1994,
    author: 'John Joven',
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Historias de Chapiyork',
    year: 2017,
    author: 'La Desparchada',
    author2: 'Daniela Ardila',
  },
  {
    title: 'Historias de indias',
    year: 1990,
    author: 'Oscar & José Campo H.',
  },
  {
    title: 'Historias e histerias de amor',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'historieta conjunta. ¿Cómo se llama?',
    year: 2008,
    author: 'Dream Tales',
    author2: 'Blue Waters Productions',
  },
  {
    title: 'historietas quindianas',
    year: 1990,
  },
  {
    title: 'Historietas solitarias',
    year: 2012,
    author: 'Felipe Camarho Rojas',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Luto (portada)',
    author4: 'Jessica Garzón (diseño)',
  },
  {
    title: 'Hixtorieta',
    year: 1999,
    author: 'Javier Kaparó (Ilustrador cubano)',
    author2: 'Bernardo Rincón (guión)',
  },
  {
    title: 'Hombres de acero',
    year: 1998,
    author: 'Carlos Alberto Osorio (Ilustrador)',
  },
  {
    title: 'Homo Capensis',
    year: 2021,
    author: "Re'em Camargo (Ilustrador)",
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'Hormiga (exhibicionismo)',
    year: 1996,
    author: 'Hormiga',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Humoris',
    year: 2008,
    author: 'Jorge Grosso y compañía',
  },
  {
    title: 'Ibaná',
    year: 1975,
    author: 'R. McCormic (Ilustrador)',
    author2: 'M.Puerta (guión)',
  },
  {
    title: 'Imágenes premonitorias',
    year: 2019,
    author: "Re'em Camargo (Ilustrador)",
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'Inconclusa No. 1',
    year: 1996,
    author: 'Villada',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Inconclusa No. 2',
    year: 1997,
    author: 'Villada',
    author2: 'Bernardo Rincón (director)',
    author3: 'Alejandro Gutiérrez (portada) (México)',
  },
  {
    title: 'Insaciable',
    year: 1996,
    author: 'Fernando Suárez',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Intro & Outro',
    year: 1996,
    author: 'Quintero',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Invation',
    year: 2023,
    author: 'Art.Jhonson (Escritor)',
    author2: 'Iván Pérez (Arte)',
    author3: 'Cesar Saba (Arte)',
    author4: 'Lu.Artistika (Color de portada y rotulación)',
  },
  {
    title: 'Investigando una investigación',
    year: 2021,
    author: 'Lina Flórez (Escritora)',
    author2: 'Altais (Artista)',
  },
  {
    title: 'Invitado',
    year: 1993,
    author: 'Jarape',
    author2: 'Bernardo Rincón (director)',
    author3: 'Rodez (carátula)',
  },
  {
    title: 'Isaac',
    year: 1992,
    author: 'Isaac',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Isaac',
    year: 1992,
    author: 'Isaac',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Ismaik: Masacrador de Sombras',
    year: 2019,
    author: 'El Suesk (Escritor)',
    author2: 'Nicete (Ilustrador y Co-creador)',
    author3: 'Andrestoryart (Portadas)',
    author4: 'Carlos Martínez (Colorista Invitado), Champe (Colorista)',
  },
  {
    title: 'Jaffa',
    year: 1993,
    author: 'Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Rodez (carátula)',
  },
  {
    title: 'Jaffa again',
    year: 1994,
    author: 'Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Jaguar',
    year: 1992,
    author: 'Jaguar',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Jaimito',
    year: 1962,
    author: 'Carlos Cajiao',
    author2: 'Fernando Acosta',
  },
  {
    title: 'Javier',
    year: 1996,
    author: 'Evaristo Ramos',
  },
  {
    title: "Jefe O'hara",
    year: 2005,
    author: 'Jean Zapata (Ilustrador)',
    author2: 'Pablo Guerra (guión)',
  },
  {
    title: 'JesuCristina',
    year: 2001,
    author: 'No (Antes Quiló)',
  },
  {
    title: 'Jhonny Dark',
    year: 1993,
    author: 'Esteban Paris (guion y dibujos)',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Jojoa',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Juan Paradoja',
    year: 1980,
    author: 'Darry',
  },
  {
    title: 'Juego: sed de poder',
    year: 1994,
    author: 'Giles y Santiago',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Julián y la Armadura de Áxnatec',
    year: 2013,
    author: 'Juan Pablo Elías Silva (Escritor)',
    author2: 'David Andrés Barbosa (Artista)',
  },
  {
    title: 'Jumentos de Belén',
    year: 1996,
    author: 'Kaparó (dibujos) Bencomo (guión)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Kalamidades',
    year: 1996,
    author: 'Kal',
    author2: 'Bernardo Rincón (director)',
  },
  {
    title: 'Kamikaze comix',
    year: 2001,
    author: 'Nitovic & Spawn',
  },
  {
    title: 'Kandrú',
    year: 1994,
    author: 'Jarape',
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Kaópolis (Saga Mysterion)',
    year: 2020,
    author: 'Keco Olano (Ilustrador)',
    author2: 'Mario Mendoza (guión)',
    author3: 'Heidi Muskus (maquetas 3d y montaje)',
    author4: 'Keco Olano y Gabriel Pedroza (color)',
  },
  {
    title: 'Kapax revisitado parte 1',
    year: 2011,
    author: 'Henry Holguín (Guión)',
    author2: 'Gustavo González (Ilustraciones)',
    author3:
      'Inu Waters basada en la fotonovela de Editorial Icavi publicada en 1976',
    author4:
      'Daniel Jiménez Quiroz (director) John Joven (portada) Sebastián Rubiano, Pedro Giraldo (asistencia editorial e ilustración) Jessica Garzón (diseño)',
  },
  {
    title: 'Kapax revisitado parte 2',
    year: 2011,
    author: 'Henry Holguín (Guión)',
    author2: 'Gustavo González (Ilustraciones)',
    author3:
      'Inu Waters basada en la fotonovela de Editorial Icavi publicada en 1976',
    author4:
      'Daniel Jiménez Quiroz (director) PowerPaola (portada) Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Kapax revisitado parte 3',
    year: 2012,
    author: 'Henry Holguín (Guión)',
    author2: 'Gustavo González (Ilustraciones)',
    author3:
      'Inu Waters basada en la fotonovela de Editorial Icavi publicada en 1976',
    author4:
      'Daniel Jiménez Quiroz (director) Luto (portada) Jessica Garzón (diseño)',
  },
  {
    title: 'Karma',
    year: 2017,
    author: 'Mateo Nieto',
  },
  {
    title: 'Kemchs',
    year: 1993,
    author: 'Kemchs',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada) Grosso (Concepto)',
  },
  {
    title: 'Kempchs',
    year: 1992,
    author: 'Kempchs',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Klan! destinos',
    year: 1996,
    author: 'José Campo H.',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Knight Karashi',
    year: 2003,
    author: 'Diana María Medellín',
  },
  {
    title: 'Krista Allen',
    year: 2008,
    author: 'Nomás (Tomás Arango)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Kuraka',
    year: 2008,
    author: 'Carlos Sterling',
  },
  {
    title: 'La audacia instintiva de un emprendedor',
    year: 2022,
    author: 'Juan Gonzalo Betancur Betancur (Director)',
    author2:
      'Semillero de Investigación y Creación en Narrativas Periodísticas, Universidad EAFIT (Grupo Investigador)',
  },
  {
    title: 'La Búsqueda',
    year: 2019,
    author: 'Andrés Ocampo (ilustador)',
    author2: 'Paula Pino (guión)',
    author3: 'Javier Peña-Ortega (guión)',
    author4:
      'Javier Peña-Ortega (portada) Paula Pino (contracarátula) Carlos Bastidas Zambrano (editor) Luisa Gustín Cabila (editora gráfica)',
  },
  {
    title: 'La Búsqueda (Altais)',
    year: 2012,
    author: 'Altais',
  },
  {
    title: 'La Búsqueda II (Altais)',
    year: 2020,
    author: 'Altais (Ilustrador y Escritor)',
  },
  {
    title: 'La cámara oscura del Olimpo',
    year: 2020,
    author: 'Rodrigo Lucio (Guionista)',
    author2: 'Carlos Dearmas (Dibujante)',
  },
  {
    title: 'La capitana',
    year: 1983,
    author: 'Hermanos Valbuena',
    author2: 'Liliana Cadavid y Marta de la Rosa (guión)',
    author3: 'José Juliao',
    author4: 'Bernardo Rincón Unomás (rotulación)',
  },
  {
    title: 'La chica en la estrella',
    year: 2010,
    author: 'Kwaichang Kráneo (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'La Colonia',
    year: 1981,
    author: 'Bernardo Ríos (historietista)',
  },
  {
    title: 'La Conquista de Masal',
    year: 2021,
    author: 'Daniel José Penagos (Ilustrador y Escritor)',
    author2: 'Oscar Torres (Editor)',
    author3: 'Sebastian Amaya (Diseñador)',
  },
  {
    title: 'La creación II',
    year: 1993,
    author: 'Mejía',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'La dama de noche',
    year: 1987,
    author: 'Libardo Mora',
    author2: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'La evolución',
    year: 1993,
    author: 'Diego Tobón',
    author4: 'Diego Tobón, Flakoz, Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'La familia Tarapués',
    year: 1983,
    author: 'Quique (Pedro Pablo Enríquez)',
  },
  {
    title: 'La feria de los no Mutantes',
    year: 1997,
    author: 'Fernando Suárez',
  },
  {
    title: 'La flaca',
    year: 1993,
    author: 'Eddy',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'La fuerza de la tierra',
    year: 2020,
    author: 'Diana Ospina (Guion)',
    author3: 'Comisión de la Verdad',
    author4:
      'CONCEPTO Y DIRECCIÓN Marta Ruiz Comisionada de la Verdad Bibiana Mercado Coordinadora Objetivo de Reconocimiento Lisa Neisa Directora ClickArte S.A.S. Emmanuel Neisa Director ClickArte S.A.S. CLICKARTE S.A.S.  Katia Rodríguez Edición Catalina Buitrago Investigación y pedagogía PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'La fuga',
    year: 1992,
    author: 'Pepe',
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'La Gaitana',
    year: 1970,
    author: 'Serafín Diaz (Autor)',
  },
  {
    title: 'La Gaitana',
    year: 1970,
    author: 'Serafín Diaz (Autor)',
  },
  {
    title: 'La gaitana',
    year: 1995,
    author: 'Roque',
    author2:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
    author3: 'Roque (portada)',
  },
  {
    title: 'La Gran Fiesta de Goranchacha',
    year: 2022,
    author: 'Felipe Forero',
  },
  {
    title: 'La guerra no sana heridas',
    year: 2021,
    author: 'Comisión de la Verdad',
    author4:
      'CONCEPTO Y DIRECCIÓN Marta Ruiz Comisionada de la Verdad Bibiana Mercado Coordinadora Objetivo de Reconocimiento Lisa Neisa Directora ClickArte S.A.S. Emmanuel Neisa Director ClickArte S.A.S. CLICKARTE S.A.S. Diana Ospina Guion Katia Rodríguez Edición Catalina Buitrago Investigación y pedagogía .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'La Hija de Atlas',
    year: 2017,
    author: 'Luis Gómez',
  },
  {
    title: 'La historia de Colombia',
    year: 1972,
    author: 'Antonio Caballero (caricaturista)',
  },
  {
    title: 'La hora Varney',
    year: 1993,
    author: 'Diego Tobón',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'La loma 90210',
    year: 1993,
    author: 'Diego K',
    author3: 'Andrés y Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'La Loma de la Cruz',
    year: 2016,
    author: 'Warner Wallis (Autor)',
    author2: 'Nelson Zuluaga (editor)',
    author3: 'Kemo Sabi (diseño y diagramación)',
    author4:
      'Corporación de cine club Borges (Jamie A. Ballesteros, John W. Ospina, Luis F. Espinal, Michael Hahn, Nelson Zuluaga)',
  },
  {
    title: 'La madremonte',
    year: 1995,
    author: 'Calarcá',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'La Mancha',
    year: 2013,
    author: 'Andrea Lucio (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'La otra vaina',
    year: 1993,
    author: 'Maxo (Max Milfort Blandón)',
    author2: 'Andrés y Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'La patasola',
    year: 1994,
    author: 'Calarcá',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'La perrata',
    year: 1996,
    author: 'Fernando y Ricardo Medina',
    author2: 'Vórax',
    author3: 'Martín Morales',
    author4: 'Giovanny Cabrera, Carlos Jiménez, César Candela y Rafael Puyana',
  },
  {
    title: 'La piquiña',
    year: 1993,
    author: 'Mauricio García',
    author2: 'Eduard Herrera',
    author3: 'Alex Cuervo',
  },
  {
    title: 'La piquiña',
    year: 1993,
    author: 'Eddy & Mauro',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'La princesa Creolina',
    year: 1987,
    author: 'Jorge Grosso',
  },
  {
    title: 'La puerca dimensión oscura',
    year: 1997,
    author: 'Ann Bessudo',
    author2: 'Bernardo Rincón (director)',
    author3: 'Alejandro Gutiérrez (portada) (México)',
  },
  {
    title: 'La raza',
    year: 1973,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'La Saga Lemuria: El Fuego Interior',
    year: 2017,
    author: 'Luis Enrique Silva Gaitan (Escritor)',
    author2: 'Jose Luis Arteaga (Ilustrador)',
    author3: 'Mattheo Osorio (Ilustrador)',
  },
  {
    title: 'La semilla',
    year: 2010,
    author: 'Martín M. Muntaner (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'La Senda de la Dama',
    year: 2019,
    author: 'Sergio Corradine (Autor)',
  },
  {
    title: 'La sentencia',
    year: 1981,
    author: 'Bernardo Ríos (historietista)',
  },
  {
    title: 'La Sierra',
    year: 2019,
    author: 'Lynda Acosta (ilustración y guión)',
    author2: 'Javier Peña-Ortega (guión)',
    author3: 'Javier Peña-Ortega (portada)',
    author4:
      'Carlos Bastidas Zambrano (editor), Luisa Gustín Cabila (editora gráfica) Paula Pino (contracarátula)',
  },
  {
    title: 'La sopa',
    year: 2009,
    author: 'Pablo Marín (el señor Juanito)',
    author2: 'Pedro josé Giraldo (director), Daniel Jiménez Quiroz (editor)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'La T.V. lesión',
    year: 1993,
    author: 'Lobo',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'La tomata de la embajada',
    year: 1981,
    author: 'Jorge Duarte',
  },
  {
    title: 'La tortura',
    year: 1979,
    author: 'Ricardo Potes (dibujos)',
    author2:
      'Enrique Buenaventura (guión adaptado de su obra teatral Los papeles del infierno)',
    author4:
      'Hans Anderegg, Ricardo Potes, Diego Pombo, León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'La tortura',
    year: 1987,
    author: 'Diego González',
    author2: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'La tribu',
    year: 2022,
    author: "Re'em Camargo (Ilustrador)",
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'La vida de Olaya Herrera',
    year: 1945,
    author: 'Augusto Quevedo',
  },
  {
    title: 'La vida de Perejil',
    year: 1926,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'La vida del general Santander',
    year: 1940,
    author: 'Lisandro Serrano (Ilustrador)',
  },
  {
    title: 'La Visita del Papa Fran-X',
    year: 2017,
    author: 'Varios artistas',
    author2: 'Juan Pablo Elías Silva (Director)',
    author3: 'Roberto Medina (Artista Invitado)',
    author4:
      'Feno Mozo (Artista Invitado), Alexander Cendales (Artista Invitado), Camilo Otálora (Artista Invitado), Mutante (Artista Invitado), Juan Pablo Solarte (Artista Invitado), Julián Naranjo (Artista Invitado), Rodrigo Babilonia (Artista Invitado), Iván Moreno (Artista Invitado)',
  },
  {
    title: 'La Vorágine de José Eustasio Rivera (Novela Gráfica)',
    year: 2017,
    author: 'Óscar Pantoja',
    author2: 'José Luis Jiménez',
  },
  {
    title: 'Labio de Liebre de Fabio Rubiano',
    year: 2020,
    author: 'Pipex',
  },
  {
    title: 'Lagañas',
    year: 2011,
    author: 'Luto',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Larva No. 1',
    year: 2006,
    author: 'Frank Arbelo (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Las abejitas y las florecitas',
    year: 1993,
    author: 'Maxo (Max Milfort Blandón)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos), Andrés y Vargas (editores)',
  },
  {
    title: 'Las aventuras de Caimán - La leyenda',
    year: 1992,
    author: 'Grosso',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Las aventuras de Gato (El tesoro de la Santamaría)',
    year: 1995,
    author: 'Daniel Rabanal (Autor)',
  },
  {
    title: 'Las aventuras de Gato (El triángulo de la Candelaria)',
    year: 1995,
    author: 'Daniel Rabanal (Autor)',
  },
  {
    title: 'Las aventuras de Gato (La hermandad de Agua Clara)',
    year: 1995,
    author: 'Daniel Rabanal (Autor)',
  },
  {
    title: 'Las aventuras de Gato (La isla del Griego)',
    year: 1995,
    author: 'Daniel Rabanal (Autor)',
  },
  {
    title: 'Las aventuras de la biscalía: El extraño caso de Waternest gate',
    year: 1995,
    author: 'Roque',
    author2: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'Las Aventuras de María Colora',
    year: 2018,
    author: 'Said Atala',
    author2: 'Andrés Santamaría',
  },
  {
    title: 'Las Aventuras de Tigrillo',
    year: 2021,
    author: 'Johann García',
    author2: 'Julián González',
    author3: 'David García',
  },
  {
    title: 'Las Candeva',
    year: 2017,
    author: 'Sindy Elefante (Ilustradora y Escritora)',
  },
  {
    title: 'Las chuchas',
    year: 2010,
    author: 'Luis Echavarriía (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Las nere as',
    year: 1993,
    author: 'Pepe',
    author2: 'Bernardo Rincón (director)',
    author3: 'Rodez (carátula)',
  },
  {
    title: 'Las vicisitudes de un historietista que no supo qué contar',
    year: 2016,
    author: 'Altais',
  },
  {
    title: 'Lemon Tree',
    year: 2021,
    author: 'Gustavo Martinez "TavoMarzz"',
  },
  {
    title: 'Leocomix',
    year: 1992,
    author: 'Leocomix',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Ley de fuga',
    year: 1987,
    author: 'Ricardo Potes (adaptación y dibujo)',
    author2: 'Carlos Bastidas Padilla (original)',
    author3: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'Leyenda No. 2',
    year: 2005,
    author: 'Andrés "Xego" Segovi',
    author2: 'Andrés Kujabanti',
    author3: 'Diego Alejandro Hincapie',
    author4: 'Luis Gabriel Trejos',
  },
  {
    title: 'Leyenda No. 3',
    year: 2005,
    author: 'Andrés "Xego" Segovi',
    author2: 'Andrés Kujabanti',
    author3: 'Diego Alejandro Hincapie',
    author4: 'Luis Gabriel Trejos',
  },
  {
    title: 'Leyenda No1',
    year: 2004,
    author: 'Andrés "Xego" Segovi',
    author2: 'Andrés Kujabanti',
    author3: 'Diego Alejandro Hincapie',
    author4: 'Luis Gabriel Trejos',
  },
  {
    title: 'Liborina',
    year: 2020,
    author: 'Luis Echavarría Uribe (Autor)',
  },
  {
    title: 'Limbo x',
    year: 1997,
    author: 'Giovanni Rozo',
    author2: 'Álvaro Andrés Amaya',
  },
  {
    title: 'Líquidos púrpuras transitan',
    year: 1994,
    author: 'Mono & Miller',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Llueve otra vez',
    year: 2010,
    author: 'Frank Arbelo (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Lo Invisible',
    year: 2019,
    author: 'Nana Sanclemente (Autora)',
  },
  {
    title: 'Lo más negro de este país es el racionamiento',
    year: 1992,
    author: 'Mock',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Lo que el globo se llevó',
    year: 2009,
    author: 'Mr. Z (Pedro José Giraldo)',
    author2: 'Pedro José Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Daniel Jiménez Quiroz (editor) Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Loca Academia Japonesa',
    year: 2017,
    author: 'El Suesk (Escritor)',
    author2: 'Feno Mozo (Ilustrador y Co-creador)',
    author3: 'Silvis Sanabria (Artista Invitada)',
    author4: 'Nicete (Artista Invitado)',
  },
  {
    title: 'Lorro',
    year: 1994,
    author: 'Diego',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Los Chiconautas',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Los conquistadores',
    year: 1970,
    author: 'LaTorre, Valbuena y Rojo (Ilustradores)',
    author2: 'Espinosa & Navas (Guión)',
  },
  {
    title: 'Los conquistadores',
    year: 1977,
    author: 'LaTorre, Valbuena y Rojo (Ilustradores)',
    author2: 'Espinosa & Navas (Guión)',
  },
  {
    title: 'Los Cuidapalos',
    year: 1982,
    author: 'Jaime López',
  },
  {
    title: 'Los Cuidapalos (primera publicación)',
    year: 1981,
    author: 'Jaime López',
  },
  {
    title: 'Los emergentes',
    year: 1982,
    author: 'León Octavio',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Los emergentes',
    year: 1979,
    author: 'León Octavio',
    author4:
      'Hans Anderegg, Ricardo Potes, Diego Pombo, León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Los emergentes',
    year: 1980,
    author: 'León Octavio',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'Hans Anderegg, León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Los emergentes',
    year: 1984,
    author: 'León Octavio',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Los Ers: el borracho está hablando',
    year: 2008,
    author: 'Inu Waters',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Pedro josé Giraldo (director), Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Los excesos del Prodigio (testimonio de Gustavo Galvis)',
    year: 2014,
    author: 'Altais (Adaptación gráfica)',
    author2: 'Gustavo Galvis (Texto original)',
  },
  {
    title: 'Los Fugitivos',
    year: 2022,
    author: 'Keco Olano (Ilustrador y arte conceptual)',
    author2: 'Mario Mendoza (guión)',
    author3: 'Heidi Muskus (maquetas 3d y montaje)',
    author4: 'Keco Olano y Gabriel Pedroza (diseño de props)',
  },
  {
    title: 'Los Híbridos',
    year: 2020,
    author: 'Luisa F. Rojas (Ilustradora)',
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'Los inseparables',
    year: 1980,
    author: 'Gilpar basado en la obra de Umberto Valverde (Cali)',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'Hans Anderegg, León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Los invasores',
    year: 1975,
    author: 'Elkin Obregón (Autor)',
  },
  {
    title: 'Los macarrones de mi novia',
    year: 2011,
    author: 'Jim Pluk',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración) Jessica Garzón (diseño)',
  },
  {
    title: 'Los Marcianitos',
    year: 1982,
    author: 'Efraim Monroy',
  },
  {
    title: 'Los marcianos',
    year: 2009,
    author: 'Mr. Z (Pedro José Giraldo)',
    author2: 'Dante Glück (Juan Jaramillo) (guión)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Pedro José Giraldo (director) Daniel Jiménez Quiroz (editor) Sebastián Rubiano (Diseño) Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración)',
  },
  {
    title: 'Los minimonstruos',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Los mitos Colombianos',
    year: 2007,
    author: 'Karmao y Daisy',
  },
  {
    title: 'Los monstruos',
    year: 2011,
    author: 'Luis Echavarría (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Los Once',
    year: 2014,
    author: 'Miguel Jiménez (Autor integral)',
    author2: 'José Luis Jiménez (Autor integral)',
    author3: 'Andrés Cruz Barrera (Autor integral)',
  },
  {
    title: 'Los Perdidos',
    year: 2013,
    author: 'Pablo Guerra',
    author2: 'Federico Neira',
  },
  {
    title: 'Los pescados de Inés la olorosa',
    year: 1994,
    author: 'Max Milfort (dibujos)',
    author2: 'Andrés vargas (guión)',
    author3: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Los sardinos',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Los sueños de Pandora',
    year: 1987,
    author: 'Álvaro Grajales',
    author2: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'Los-ers en: novia',
    year: 2009,
    author: 'Inu (Javier Posada)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Daniel Jiménez Quiroz (editor) Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Luces Nocturnas',
    year: 2017,
    author: 'Lorena Álvarez (Autora)',
    author2: 'Gonzalo Quesada (traducción)',
    author3: 'Ana González de la Peña (rotulación)',
    author4: 'Manuel Bartual (maquetación)',
  },
  {
    title: 'Lucrecia',
    year: 1993,
    author: 'Lucía Lozano',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'Lunátika No. 2',
    year: 2005,
    author: 'Constanza Espitia',
  },
  {
    title: 'Lunátika No. 3',
    year: 2005,
    author: 'Constanza Espitia',
  },
  {
    title: 'Luto Rosa (el señor)',
    year: 2011,
    author: 'Luto',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Luto Rosa parte 2',
    year: 2011,
    author: 'Luto',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Magical love',
    year: 2003,
    author: 'Valentina Ruiz',
  },
  {
    title: 'Magnético',
    year: 1994,
    author: 'Giovanni Rozo',
    author2: 'Álvaro Andrés Amaya',
  },
  {
    title: 'Magola',
    year: 1996,
    author: 'Nani (Autora)',
  },
  {
    title: 'Magola (Sobreviviendo en pareja)',
    year: 2007,
    author: 'Nani (Autora)',
  },
  {
    title: 'Magola 15 años',
    year: 2010,
  },
  {
    title: 'Makú',
    year: 1964,
    author: 'Carlos Garzón (Ilustrador)',
    author2: 'Jorge Peña',
    author3: 'Nelson Ramírez',
    author4: 'Julio Jiménez',
  },
  {
    title: 'Maldita',
    year: 1999,
  },
  {
    title: 'Maldita Meduza',
    year: 1998,
  },
  {
    title: 'Mama no hay sino una',
    year: 1993,
    author: 'Leocomix (dibujo)',
    author2: 'Gilles (guion)',
    author3: 'Rodez (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Mambo: Cat in the Box',
    year: 2017,
    author: 'Bastian Matiz',
  },
  {
    title: 'Manga',
    year: 2005,
    author: 'Juan Cristóbal Calle (Ilustrador)',
  },
  {
    title: 'Manual de una buena secretaria',
    year: 1979,
    author4:
      'Hans Anderegg, Ricardo Potes, Diego Pombo, León Octavio, Gilberto Parra, Felipe Ossa Ilustraciones tomadas de las revistas Manual de una buena secretaria',
  },
  {
    title: 'Mara',
    year: 1998,
    author: 'Rodrigo Estupiñan',
    author2: 'Juan Calle',
    author3: 'Boris Greiff',
    author4: 'Andrés Rey',
  },
  {
    title: 'María de Jorge Isaacs (Novela gráfica)',
    year: 2020,
    author: 'Oscar Adán',
  },
  {
    title: 'Marilú',
    year: 2007,
    author: 'Nani (Autora)',
  },
  {
    title: 'Más historias de animalitos',
    year: 2009,
    author: 'Joni B',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Daniel Jiménez Quiroz (editor) Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Matilde',
    year: 1987,
    author: 'José A. Franco (dibujos)',
    author2: 'Ana María Rojas (guión)',
    author3: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'Maxturbo',
    year: 2000,
  },
  {
    title: 'Mayhem Goddess',
    year: 2020,
    author: 'Demonbloodpal (DBP)',
  },
  {
    title: 'Mea culpa',
    year: 1994,
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Mentescape',
    year: 2019,
    author: 'S.M. Casimir (Autor)',
    author2: 'Andrés Cruz Barrera (director editorial)',
    author3: 'Sergio Maldonado, Andrés Cruz (Producción ejecutiva)',
  },
  {
    title: 'Messiahs And Martyrs',
    year: 2020,
    author: 'Zain Machaal (Escritor)',
    author2: 'Fuyuhiro Gushiken (Ilustrador)',
  },
  {
    title: 'Metamorfobia',
    year: 1998,
  },
  {
    title: 'Mi cuerpo dice la verdad',
    year: 2019,
    author: 'Comisión de la Verdad',
    author4:
      'CONCEPTO Y DIRECCIÓN Marta Ruiz Comisionada de la Verdad Bibiana Mercado Coordinadora Objetivo de Reconocimiento Lisa Neisa Directora ClickArte S.A.S. Emmanuel Neisa Director ClickArte S.A.S. CLICKARTE S.A.S. Diana Ospina Guion Katia Rodríguez Edición Catalina Buitrago Investigación y pedagogía .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'Mi gatito duerme cincuenta centímetros bajo tierra',
    year: 2011,
    author: 'Mr. Z',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'John Joven (portada)',
    author4:
      'Sebastián Rubiano, Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Mi primer amor',
    year: 1996,
    author: 'Puyana',
    author2: 'Bernardo Rincón (director)',
  },
  {
    title: 'Midori',
    year: 2006,
    author: 'Laskó (Diana Medellín y Andrés Rozo)',
  },
  {
    title: 'Mientras la ciudad duerme',
    year: 1996,
    author: 'José Campo H.',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Miércoles...',
    year: 1982,
    author: 'Ricardo Potes (adaptación y dibujos)',
    author2: 'Eduardo Galeano (historia)',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Mini ficciones',
    year: 2008,
    author: 'Melissa Kojima (U.S.A. ilustración)',
    author2: 'José Raúl Jaramillo (relatos)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial),  Pedro josé Giraldo (director), Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Mirar y no tocar',
    year: 1995,
    author: 'Orlando Caballero (guión y diujos)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Misia Escopeta',
    year: 1946,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'Miss cosas',
    year: 1988,
    author: 'Ceci (Cecilia Cáceres)',
  },
  {
    title: 'Mito Colombiano: El boraro',
    year: 1995,
    author: 'Calarcá',
    author2: 'Roque (portada)',
    author3: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'Mitos urbanos de Venezuela',
    year: 2009,
    author: 'Clan Nahualli',
  },
  {
    title: 'Mochilero',
    year: 2020,
    author: 'Verdísimo',
  },
  {
    title: 'Mojicón',
    year: 1924,
    author: 'Adolfo Samper (Autor, Ilustrador)',
    author2: 'Arturo Manrique (editor)',
  },
  {
    title: 'Mojicón',
    year: 1928,
    author: 'Adolfo Samper (Autor, Ilustrador)',
    author2: 'Arturo Manrique (editor)',
  },
  {
    title: 'Mojicón Feliz año nuevo 1930 (Mojicón y Bizcochito)',
    year: 1930,
    author: 'H. Manrique (Autor)',
  },
  {
    title: 'Mongotours',
    year: 1994,
    author: 'Carolina Reyes',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Monstruos',
    year: 2015,
    author: 'Juan Dapo (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Montecristo',
    year: 1985,
    author: 'Diego Toro (ilustraciones)',
    author2: 'Jairo A.',
    author3: 'Sergio Toro (ilustraciones)',
    author4: 'Luis Fernando, Bernardo Rincón (ilustraciones)',
  },
  {
    title: 'Morgellons',
    year: 2021,
    author: 'Juan David Peñaranda (ilustraciones)',
    author2: 'Keco Olano (Dirección y storyboards)',
    author3: 'Mario Mendoza (guión)',
    author4: 'Heidi Muskus (Maquetas 3d, montaje y concepto contracarátula)',
  },
  {
    title: 'Mortero',
    year: 1995,
    author: 'Juan Carlos 8A & Concha',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Mosca',
    year: 1998,
  },
  {
    title: 'Mousedini: Más allá de la Jaula',
    year: 2023,
    author: 'Alberto Fuentes (Ilustrador)',
    author2: 'Jose Fuentes (Escritor)',
  },
  {
    title: 'Movimiento y expresión en el dibujo humorístico',
    year: 1996,
    author: 'Germán Fernández',
  },
  {
    title: 'Múcura',
    year: 2018,
    author: 'Mateo Nieto',
  },
  {
    title: 'Mugrosso',
    year: 1989,
    author: 'Jorge Grosso',
  },
  {
    title: 'Mugrosso',
    year: 1992,
    author: 'Grosso',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Mugrosso',
    year: 1993,
    author: 'Grosso',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Mujer gala',
    year: 1994,
    author: 'Bernardo Rincón',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Música a todo',
    year: 1945,
    author: 'López Ruiz',
  },
  {
    title: 'Ñ Comme Viñetas',
    year: 2017,
    author: 'Camilo Aguirre',
    author2: 'Henry Díaz',
    author3: 'Luis Echavarría',
    author4:
      'Sindy Elefante, Juan & Diego, La Watson, Luto, El Señor, m. a. noregna, Jim Pluk, Powerpaola, Camilo Vieco, Inu Waters',
  },
  {
    title: 'N.N.',
    year: 1996,
    author: 'Marco Pinto (guión y didujos)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Kaparó (portada) (Cuba)',
  },
  {
    title: 'Nahualli Comics',
    year: 2008,
    author: 'Camilo A. Triana C.',
    author2: 'Henry Díaz',
    author3: 'Giovanni Nieto',
  },
  {
    title: 'Naide',
    year: 1993,
    author: 'Naide',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada) Grosso (Concepto)',
  },
  {
    title: 'Necba',
    year: 1993,
    author: 'Armando Rosales (dibujos)',
    author2: 'Giles Fauveau (guion)',
    author3: 'Rodez (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Necba',
    year: 1993,
    author: 'Armando Rosales (dibujos)',
    author2: 'Gilles (guion)',
    author3: 'Giovanni Castro(portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Negocio de familia',
    year: 1993,
    author: 'Gilles & Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Giovanni Castro(portada)',
  },
  {
    title: 'Nekonoroi',
    year: 2018,
    author: 'Silvis Sanabria (Autora)',
  },
  {
    title: 'Neocomics',
    year: 1997,
    author: 'Estudiantes del instituto departamental de bellas artes',
  },
  {
    title: 'Neura',
    year: 2002,
    author: 'Diego Álvarez',
    author2: 'Karmao',
  },
  {
    title: 'Nimrod',
    year: 2002,
    author: 'Diego Gómez',
  },
  {
    title: 'Nine',
    year: 1993,
    author: 'Nine',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada) Grosso (Concepto)',
  },
  {
    title: 'No es Color para los Débiles',
    year: 2017,
    author: 'Feno Mozo (Autor)',
  },
  {
    title: 'No es para tanto',
    year: 2010,
    author: 'Mariana Gil',
    author3: 'Nobara Hayakawa (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración Daniel Jiménez Quiroz (editor), Pedro josé Giraldo, Mauricio Giraldo, Félix Salamanca, Hugo Ruiz (asistencia editorial) Paola Gaviria (editora invitada)',
  },
  {
    title: 'No mires a quien',
    year: 1993,
    author: 'Tyto Pérez',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'No olvidaré sus nombres',
    year: 2021,
    author: 'Comisión de la Verdad',
    author4:
      'CONCEPTO Y DIRECCIÓN Marta Ruiz Comisionada de la Verdad Bibiana Mercado Coordinadora Objetivo de Reconocimiento Lisa Neisa Directora ClickArte S.A.S. Emmanuel Neisa Director ClickArte S.A.S. CLICKARTE S.A.S. Diana Ospina Guion Katia Rodríguez Edición Catalina Buitrago Investigación y pedagogía .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'Nocturno',
    year: 1993,
    author: 'Pepe',
    author2: 'Bernardo Rincón (director)',
    author3: 'Giovanni Castro(portada)',
  },
  {
    title: 'Nocturno',
    year: 1993,
    author: 'Pepe Peña',
    author2: 'Bernardo Rincón (director)',
    author3: 'Bernardo Rincón (portada)',
  },
  {
    title: 'Nuclear: 24 Segundos para medianoche',
    year: 2024,
    author3: 'Daniel Penagos (Co-Escritor)',
    author2: 'Nicete (Ilustrador)',
    author: 'Daniel González (Escritor)',
    author4: 'Oscar torres (Editor) Sebastian Amaya (diseñador)',
  },
  {
    title: 'Nuestra tele',
    year: 2008,
    author: 'Mr. Z (ilustraciones) Tomás Guzmán (textos)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección) Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial)',
  },
  {
    title: 'Nuevas aleluyas',
    year: 1929,
    author: 'Anónimo',
  },
  {
    title: 'Obstáculo del amor',
    year: 2021,
    author: "Brayan Rojas ''SaikoBr04''",
  },
  {
    title: 'Obtura: nada del otro mundo',
    year: 2018,
    author: 'Daniel Greiff (Escritor)',
    author2: 'Gusanillo (Ilustrador)',
    author3: 'Said Atala (Editor)',
  },
  {
    title: 'Ocaso Heróico',
    year: 2019,
    author: 'Ericson Armando Uribe Sanbria',
  },
  {
    title: 'Ofit',
    year: 1992,
    author: 'Ofit',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Oh, deer',
    year: 2011,
    author: "Vena'o",
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'PowerPaola (portada)',
    author4:
      'Pedro Giraldo (aistencia editorial e ilustración), Jessica Garzón (diseño)',
  },
  {
    title: 'Otto - Detective privado',
    year: 1994,
    author: 'Diego',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Pacho',
    year: 1987,
    author: 'Margarita Herrera',
    author2: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'Pacho Pérez',
    year: 1992,
    author: 'Rincón & Rosales',
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'Pacho Pérez',
    year: 1993,
    author: 'Armando Rosales (dibujos)',
    author2: 'Bernardo Rincón (guión)',
    author3: 'Pepe (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Pacho y el dibujante',
    year: 1989,
    author: 'Diego Toro (Ilustrador)',
    author2: 'Jairo Álvarez Osorio (guión)',
  },
  {
    title: 'Pacho y el dibujante',
    year: 1990,
    author: 'Diego Toro (Ilustrador)',
    author2: 'Miguel Osorio (guión)',
    author3: 'Gabriel Bernal (color)',
  },
  {
    title: 'Palom arte',
    year: 1993,
    author: 'Pipe',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'Palomo',
    year: 1992,
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Palosa',
    year: 1992,
    author: 'Palosa',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Pan pa’l circo',
    year: 1991,
    author: 'Quilo',
  },
  {
    title: 'Papá Jipy',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Para los niños Mojicón',
    year: 1924,
    author: 'Adolfo Samper (Autor, Ilustrador)',
    author2: 'Arturo Manrique (editor)',
  },
  {
    title: 'Para los niños Mojicón',
    year: 1928,
    author: 'Adolfo Samper (Autor, Ilustrador)',
    author2: 'Arturo Manrique (editor)',
  },
  {
    title: 'Para todos aquellos',
    year: 1992,
    author2: 'Bernardo Rincón (director)',
    author3: 'Leocomix (portada)',
  },
  {
    title: 'Paro nacional',
    year: 1998,
  },
  {
    title: 'Parodias',
    year: 1994,
    author: 'Santiago, Creación (Gilalb)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Caramelot (portada)',
  },
  {
    title: 'Parque del Poblado',
    year: 2011,
    author: 'Joni B (Autor)',
    author4: 'Truchafrita (prólogo)',
  },
  {
    title: 'Parques nacionales',
    year: 1985,
    author: 'Tomás Estevez (Ilustrador)',
    author2: 'Universidad Nacional',
  },
  {
    title: 'Parroquiancity (la invasión del cemento)',
    year: 1982,
    author: 'Gipar estudios',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Parroquiancity: Requiem por un Chiminango',
    year: 1984,
    author: 'Gilpar estudios',
    author3: 'Ricardo Potes (coordinador)',
    author4: 'León Octavio, Gilberto Parra, Felipe Ossa (comité editorial)',
  },
  {
    title: 'Partido final',
    year: 1995,
    author: 'Kaparo',
    author2: 'Bernardo Rincón (director)',
    author3: 'Brügo (portada)',
  },
  {
    title: 'Patasola & cía',
    year: 1989,
    author: 'Jorge Grosso, Quir, Ceci, Calero, Nicholls, Roa, Rincón',
    author4: 'Quir, Ceci, Calero, Nicholls, Roa, Rincón',
  },
  {
    title: 'Pavimento',
    year: 1996,
    author: 'Keco Olano',
    author2: 'José Campo H. (director y portada)',
    author3: 'Fernando Suárez (contraportada)',
  },
  {
    title: 'Pedro Navaja',
    year: 1980,
    author: 'Ricardo Potes',
    author2: 'Ricardo Potes (coordinador)',
    author4: 'Hans Anderegg, León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Pelele el diente',
    year: 1993,
    author: 'Diego Cardona',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Pelele el diente',
    year: 1994,
    author: 'Ricky',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Peleleman',
    year: 2000,
    author: 'Diego Andrés Cardona',
    author2: 'Max Milfort',
    author3: 'Andrés Vargas',
    author4: 'Beny, Gigio',
  },
  {
    title: 'Pequeños Reinos: Colmillo de víbora',
    year: 2021,
    author: 'Jorge Armando Aroca (Director - Escritor - Guionista)',
    author2: 'Sebastian Aguirre (Ilustrador - Lineart)',
    author3: 'Lina Rodriguez (Guion Técnico - Story Board - Color)',
  },
  {
    title: 'Pide un deseo',
    year: 2021,
    author: 'Ninfa Pérez Bolívar',
  },
  {
    title: 'Pimiento',
    year: 2005,
    author: 'Lido Pimienta',
  },
  {
    title: 'Pixel Sucker',
    year: 2020,
    author: 'Juan Pablo Solarte',
  },
  {
    title: 'Plácido Bonilla en: Mi ídolo',
    year: 1994,
    author: 'Beni',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Plácido Bonilla en: Soy el heno fresco y fácil',
    year: 1993,
    author: 'Beni',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Plumas',
    year: 1993,
    author: 'Laerte',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Poeros - el 69 sueño',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Giovanni Castro (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Ilona Staller',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Pepe (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - La duquesa',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Pepe (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Lindas cubanas',
    year: 1996,
    author2: 'Kaparó (portada) (Cuba)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Louise Ciccone',
    year: 1992,
    author: 'Bernardo Rincón',
    author2: 'Leocomix (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Mathilda, Béatrice, Florence & Erica',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Rodez (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Mathilda, Béatrice, Florence & Erica',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Rodez (carátula)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Norma Jean',
    year: 1992,
    author: 'Bernardo Rincón',
    author2: 'Leocomix (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Osculos y acción)',
    year: 1996,
    author: 'Bernardo Rincón',
    author2: 'Kaparó (portada) (Cuba)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - Racquel',
    year: 1997,
    author: 'Bernardo Rincón (Autor)',
    author2: 'Alejandro Gutiérrez (portada) (México)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Poeros - top model',
    year: 1993,
    author: 'Bernardo Rincón',
    author2: 'Giovanni Castro (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Pogo de manos es de villanos, el libro de Carefukyou',
    year: 2020,
    author: 'LUTO (Luis Tobón)',
  },
  {
    title: 'Polín',
    year: 1948,
    author: 'Adolfo Samper (Autor, Ilustrador)',
  },
  {
    title: 'Pollitos',
    year: 2018,
    author: 'Ninfa Pérez Bolívar',
  },
  {
    title: 'Polvorín',
    year: 1994,
    author2: 'Gilles Fauveau (editor)',
    author3: 'Pepe & Caramelot (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Por una bandera',
    year: 2010,
    author: 'Truchafrita (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Por unos órganos más',
    year: 1995,
    author: 'Gilles & Ricardo',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'Primer anochecer',
    year: 2009,
    author: 'Truchafrita (Álvaro Vélez)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Andrezzinho (portada y contra)',
    author4:
      'Daniel Jiménez Quiroz (editor) Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Primer episodio',
    year: 1993,
    author: 'Andrés Torres',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'PRIMIGENI@S',
    year: 2017,
    author: 'Cosmointeligente (Autor)',
  },
  {
    title: 'Protín',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Psicopio',
    year: 1995,
    author: 'Osma',
    author2: 'Roque (portada)',
    author3: 'Roque (portada)',
    author4:
      'Helman Salazar, Arlés Herrera (Calarcá), Orlando Martínez, Nelson Ochoa, Germán Fernández (comité directivo),',
  },
  {
    title: 'Psircopata',
    year: 1998,
  },
  {
    title: 'Pumaki',
    year: 2012,
    author: 'Feno Mozo (Autor)',
    author2: 'El Suesk (Escritor Invitado)',
    author3: 'Rimutoki Pavach (Artista Invitado)',
    author4: 'Hache (Artista Invitado), Gusanillo (Artista Invitado)',
  },
  {
    title: 'Punto G',
    year: 1993,
    author: 'Lobo',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Qué tan muerto estás?',
    year: 1995,
    author: 'FKZ (guión y dibujos)',
    author2: 'Bernardo Rincón (director)',
    author3: 'Marco Pinto (portada)',
  },
  {
    title: 'Querubín',
    year: 1988,
    author: 'Jorge Grosso y Quir (Quiriam Pinilla)',
  },
  {
    title: 'Quiero ser de culto',
    year: 2010,
    author: 'Inu (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración',
  },
  {
    title: 'Recetario de Sabores Lejanos',
    year: 2020,
    author: 'Pablo Guerra (Guionista y Director)',
    author2: 'Diana Ojeda (Investigadora y Recopiladora)',
    author3: 'Sonia Serna Botero (Investigadora y Recopiladora)',
    author4:
      'Julio Arias Vanegas (Investigador y Recopilador), Alejandro Camargo (Investigador y Recopilador), María Alejandra Grillo (Investigadora y Recopiladora), Juan Camilo Patiño (Investigador y Recopilador), Aurora Casierra (Investigadora y Recopiladora), Natalia Quiceno Toro (Investigadora y Recopiladora), Mariela Palacios Rodríguez (Investigadora y Recopiladora), Ingrid Díaz (Investigadora y Recopiladora), Kristina Lyons (Investigadora y Recopiladora), Camilo Aguirre (Artista), Sindy Elefante (Artista), La Desparchada (Artista), Henry Díaz (Artista), Gusanillo (Artista), Camilo Vieco (Artista), Diana Sarasti (Artista), Edward Muñoz (Artista)',
  },
  {
    title: 'Regreso a la agencia 3 dedos',
    year: 1994,
    author: 'Bernardo Rincón',
    author2: 'Juan Caros Ochoa (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Relick',
    year: 2018,
    author: 'Fuyuhiro Gushiken',
  },
  {
    title: 'Reptáceo',
    year: 1993,
    author: 'Giovanni Catro',
    author2: 'Giovanni Castro(portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Resistencias trenzadas',
    year: 2021,
    author: 'Comisión de la Verdad',
    author4:
      'Taller Creativo de Aleida Sánchez B. SAS Desarrollo creativo, producción editorial y audiovisual www.tallercreativoaleida.com.co Coordinación editorial y producción general Aleida Sánchez Buitrago Guionización para versiones gráfica y audiovisual, desarrollo de actividades pedagógicas Lorenzo Camacho Téllez Jorge Camacho Velásquez Ilustración original Mario Urazán Roncancio Diseño y diagramación Aleida Sánchez Buitrago Zamara Zambrano Sánchez .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'Reto Cómic 24 Horas de Hache - 2019',
    year: 2019,
    author: 'Oscar Hernández «Hache» (historietista)',
  },
  {
    title: 'Revisar Autor Colombiano "OK" radicado en Costa Rica',
    year: 1991,
  },
  {
    title: 'Revolcón 15',
    year: 1992,
    author: 'Aponte',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Revolcón 25',
    year: 1992,
    author: 'Fish',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Revólver No. 1',
    year: 2004,
    author: 'Carlos García (Director)',
  },
  {
    title: 'Revólver No. 2',
    year: 2004,
    author: 'Carlos García (Director)',
  },
  {
    title: 'Robot',
    year: 2003,
    author: 'Truchafrita',
    author2: 'Joni B',
  },
  {
    title: 'Rockstar Bunny',
    year: 2021,
    author: 'Eliana Stefanny Gomez Tello "Eliana55226838"',
  },
  {
    title: 'Ronin',
    year: 2008,
    author: 'Altais (Pablo Pérez)',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección) Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial)',
  },
  {
    title: 'Rosario Tijeras',
    year: 2021,
    author: 'Henry Díaz (Ilustrador)',
    author2: 'Jorge Franco (historia original)',
    author3: 'Pablo Guerra (guión adaptado)',
    author4: 'Diana Sarasti, Camilo Vieco (coloristas)',
  },
  {
    title: 'S.R.T.: Satanic Rescue Team',
    year: 2021,
    author: 'El Muerto Ilustra',
    author2: 'Dr. Páncreas',
  },
  {
    title: 'Sábado',
    year: 1987,
    author: 'Fernando Arboleda',
    author2: 'Ricardo Potes (coordinador del taller)',
    author4:
      'Edición colectiva estudiantes de diseño gráfico y Artes plásticas del instituto de bellas artes: Antonio Franco, Margarita Rosa Herrera, Jorge Baquero, Álvaro Grajales, Diego González, Libardo Mora, Fernando Arboleda.',
  },
  {
    title: 'Sacro',
    year: 2001,
    author: 'Andrés Montealegre',
    author2: 'Jean Paul Bahn',
  },
  {
    title: 'San Victorino',
    year: 1989,
    author: 'Jorge Grosso',
  },
  {
    title: 'San Victorino',
    year: 1991,
    author: 'Gámez',
  },
  {
    title: 'Santiago',
    year: 1992,
    author: 'Santiago',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Satanás (Novela gráfica)',
    year: 2018,
    author: 'Mario Mendoza (Escritor)',
    author2: 'Keco Olano (Artista)',
    author3: 'Heidi Muskus (maquetas 3d y montaje)',
    author4: 'Keco Olano y Gabriel Pedroza (color)',
  },
  {
    title: 'Satín and Pro',
    year: 2019,
    author: 'Gelpat Lucas',
  },
  {
    title: 'Scorsese',
    year: 2018,
    author: 'Luisa Fernanda Rojas',
  },
  {
    title: 'Sé que vas a cambiar',
    year: 1997,
    author: 'John Joven',
    author2: 'Alejandro Gutiérrez (portada) (México)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Señora ACME',
    year: 1994,
    author: 'Santiago',
    author2: 'Juan Caros Ochoa (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Separadores de Parejas',
    year: 2021,
    author: 'Feno Mozo (Autor)',
    author2: 'Sergio Corradine (Editor)',
  },
  {
    title: 'Septa comics',
    year: 1998,
  },
  {
    title: 'Sharí',
    year: 1994,
    author: 'Alzate Jurado',
    author2: 'Juan Caros Ochoa (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Shintzine No. 3',
    year: 2004,
    author: 'Elvis',
  },
  {
    title: 'Si nos dejan',
    year: 1995,
    author: 'Nani',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'Si título (será que me llama?)',
    year: 1993,
    author: 'Nany',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Sin descanso hasta encontrarlos',
    year: 2020,
    author: 'Comisión de la Verdad',
    author4:
      'CONCEPTO Y DIRECCIÓN Marta Ruiz Comisionada de la Verdad Bibiana Mercado Coordinadora Objetivo de Reconocimiento Lisa Neisa Directora ClickArte S.A.S. Emmanuel Neisa Director ClickArte S.A.S. CLICKARTE S.A.S. Diana Ospina Guion Katia Rodríguez Edición Catalina Buitrago Investigación y pedagogía .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'Sin mascar palabra. Por los caminos de Tulapas',
    year: 2018,
    author: 'Camilo Vieco',
    author2: 'Pablo Guerra',
  },
  {
    title: 'Sin tiempo ni espacio',
    year: 1993,
    author: 'Flakoz',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: "Sisi Olina - Doomsday's blues",
    year: 1993,
    author: 'Quiló',
    author2: 'Bernardo Rincón (director)',
    author3: 'Pepe (carátula)',
  },
  {
    title: 'Small Punk',
    year: 2020,
    author: 'Eder',
    author2: 'Luisa Pérez',
  },
  {
    title: 'Sobredosis de tv',
    year: 2018,
    author: 'Andres Urán (Dibujante)',
  },
  {
    title: 'Soldados zona bananera 1928',
    year: 1978,
    author: 'Ricardo Potes (dibujos)',
    author2: 'Felipe Ossa (prólogo)',
    author3:
      'Basado en la obra literaria La casa Grande de Álvaro Cepeda Samudio y adaptación par teatro de Carlos José Reyes y Enrique Buenaventura.',
    author4: "Gilberto Parra (diseño de carátula) Gilpar'g estudios",
  },
  {
    title: 'Soledad dolores',
    year: 2010,
    author: 'Alejandra Hernández',
    author2: 'Daniel Jiménez Quiroz (editor)',
    author3: 'Nobara Hayakawa (portada)',
    author4:
      'Sebastián Rubiano (Diseño), Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración Pedro josé Giraldo, Mauricio Giraldo, Félix Salamanca, Hugo Ruiz (asistencia editorial) Paola Gaviria (editora invitada)',
  },
  {
    title: 'Solo imaginación',
    year: 1994,
    author: 'Andrea & Ximena',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Solo los extraños',
    year: 1993,
    author: 'Claudia y Quiló',
    author2: 'Giovanni Castro (portada)',
    author3: 'Giovanni Castro(portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Solo una más',
    year: 1993,
    author: 'Leocomix',
    author2: 'Giovanni Castro (portada)',
    author3: 'Giovanni Castro(portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Somos Diferentes',
    year: 2013,
    author: 'Andrea Lucio (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Sorpresa',
    year: 1993,
    author: 'Armando Rosales (dibujo)',
    author2: 'Jorge Grosso (director editorial)',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Starwars (segunda y tercera parte)',
    year: 1980,
    author: 'Carlos Garzón',
    author2: 'Al Williamson (entintado)',
  },
  {
    title: 'Statu quo',
    year: 2022,
    author: 'Daniel Greiff (Escritor)',
    author2: 'Roberto Medina (Ilustrador)',
    author3: 'Juan Pablo Elías Silva (Editor)',
    author4: 'Daniel Greiff (Autor)',
  },
  {
    title: 'Steel Fire',
    year: 2005,
    author: 'Asmed Espitia (dibujo y creación)',
    author2: 'Angelo López (dibujo y tinta)',
    author4:
      'Andrés Obregón (guión y creación), Rubén Chitiva (tintas), Jarokxy Torres (color) y William Barrera I. (diagramación)',
  },
  {
    title: 'Steel Fire No. 2',
    year: 2005,
    author: 'Asmed Espitia (dibujo y creación)',
    author2: 'Angelo López (dibujo y tinta)',
    author4:
      'Andrés Obregón (guión y creación), Rubén Chitiva (tintas), Jarokxy Torres (color) y William Barrera I. (diagramación)',
  },
  {
    title: 'Subienda',
    year: 1995,
    author: 'Fish',
    author2: 'Bernardo Rincón (director)',
  },
  {
    title: 'Subsana',
    year: 1986,
    author: 'Mina',
  },
  {
    title: 'Sudaka comix',
    year: 1995,
    author: 'Andrés, Marco, Tebo y Johnny Lester',
  },
  {
    title: 'Super Mísero',
    year: 1979,
    author: 'Jorge Saavedra (dibujo)',
    author2: "Gilpar'g (guion)",
    author4:
      'Hans Anderegg, Ricardo Potes, Diego Pombo, León Octavio, Gilberto Parra, Felipe Ossa',
  },
  {
    title: 'Super-ado',
    year: 1993,
    author: 'Flakoz',
    author2: 'Pipe (portada)',
    author3: 'Flakoz (contraportada)',
    author4:
      'Juan Marín, Alejo Lobo, Carlos Betancur, Diego Tobón (junta editorial)',
  },
  {
    title: 'Super-ado y Chepe',
    year: 1993,
    author: 'Flakoz',
    author4: 'Diego Tobón, Flakoz, Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'Superfan',
    year: 1994,
    author: 'Muyi',
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Sweet Dreams Cafe',
    year: 2014,
    author: 'Julián Naranjo (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Tarde de hormiga',
    year: 2008,
    author: 'Mr. Z',
    author2: 'Pedro josé Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección) Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial),',
  },
  {
    title: 'Tarot',
    year: 2021,
    author: 'Juancho',
  },
  {
    title: 'Tatiana de los Ríos',
    year: 2004,
    author: 'Álvaro Tangarife',
  },
  {
    title: 'Te vas a caer',
    year: 2012,
    author: 'Joni B',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Luto (portada)',
    author4: 'Jessica Garzón (diseño)',
  },
  {
    title: 'Teléfono4',
    year: 2005,
    author: 'Andrés Montealegre (Ilustrador)',
    author2: 'Pablo Guerra (guión)',
  },
  {
    title: 'Tercer mundo',
    year: 1992,
    author: 'Naide',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Terrores nocturnos',
    year: 2019,
    author: 'Carlos Granda (Autor)',
    author2: 'Cindy Johana Bula Hoyos (corrección de textos)',
  },
  {
    title: 'The Mash',
    year: 2015,
    author: 'Danigami',
    author2: 'Luis Francisco García',
  },
  {
    title: 'Tiempo anfibio',
    year: 1994,
    author: 'Leocomix',
    author2: 'Bernardo Rincón (director)',
    author3: 'Quiló (portada)',
  },
  {
    title: 'Tierra larga',
    year: 2019,
    author: 'PowerPaola (Paola Gaviria)',
  },
  {
    title: 'Timmy y Mitty',
    year: 2012,
    author: 'Truchafrita (Ilustrador)',
  },
  {
    title: 'Tiras Cómicas un ensayo de Luis López de Mesa',
    year: 2019,
    author: 'Altais (Adaptación gráfica)',
    author2: 'Luis López de Mesa (Texto original)',
  },
  {
    title: 'Tito',
    year: 1998,
    author: 'Fernando Suárez (Autor)',
  },
  {
    title: 'Todo Comienza en Algún Momento',
    year: 2013,
    author: 'Andrea Lucio (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Todo esto era nuestro',
    year: 2021,
    author: 'Paula Cárdenas (Autora)',
  },
  {
    title: 'Todo tiene un comienzo',
    year: 1994,
    author: 'Lucía Salazar V. (ilustraciones) , Ilar Ruiz (textos)',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
  {
    title: 'Toro',
    year: 2008,
    author: 'Gotapio (Luis Miguel Giraldo)',
    author2: 'Pedro José Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Transhumantes No. 3',
    year: 2002,
    author: 'Sebastián Bravo',
    author2: 'Nicolás Lozano',
    author3: 'Camilo Sanin',
    author4: 'Fernando Lozano Alejandro Castro',
  },
  {
    title: 'Transparentes',
    year: 2020,
    author: 'Javier de Isusi (Ilustrador)',
    author2: 'Francisco de Roux (presentación)',
    author3: 'Comisión de la Verdad',
    author4: 'Carlos Martín Beristain (epílogo)',
  },
  {
    title: 'Trasnshumantes',
    year: 1998,
  },
  {
    title: 'Travesía de un Chamo',
    year: 2021,
    author: 'Gerardo Jose Mejia Chala',
  },
  {
    title: 'Tres Horizontes',
    year: 2019,
    author: 'Lina Flórez (Escritora)',
    author2: 'Pablo Pérez "Altais" (Artista)',
  },
  {
    title: 'Tresrroristas',
    year: 1993,
    author: 'Pipe',
    author4: 'Diego Tobón, Flakoz, Lobo y Pipe (Autores intelectuales)',
  },
  {
    title: 'Tripulación de Papel',
    year: 2018,
    author: 'Luis Enrique Silva Gaitan (Director)',
    author2: 'Ninfa Pérez Bolívar (Artista Invitada)',
    author3: 'El Suesk (Escritor Invitado)',
  },
  {
    title: 'Tukano',
    year: 1970,
    author: 'Jorge Peña (Autor, Ilustrador)',
  },
  {
    title: 'Tukano',
    year: 1981,
    author: 'Jorge Peña (Autor, Ilustrador)',
  },
  {
    title: 'Tukano',
    year: 1981,
    author: 'Jorge Peña (Autor, Ilustrador)',
  },
  {
    title: 'Tukano (El final del aventurero)',
    year: 2005,
    author: 'Jorge Peña (Autor, Ilustrador)',
  },
  {
    title: 'Un año espantoso',
    year: 1981,
    author: 'Jorge Duarte',
  },
  {
    title: 'Un camino hacia la verdad',
    year: 2021,
    author: 'Comisión de la Verdad',
    author4:
      'CONCEPTO Y DIRECCIÓN Francisco de Roux Rengifo Presidente de la Comisión de la Verdad Carlos Martín Beristain Comisionado de la Comisión de la Verdad Leyner Palacios Asprilla Comisionado de la Comisión de la Verdad Marta Ruiz Naranjo Comisionada de la Comisión de la Verdad Pavel Santodomingo Aguilar Bibiana Mercado Rivera Objetivo de Reconocimiento de la Comisión de la Verdad Lisa Neisa Directora ClickArte S.A.S. Emmanuel Neisa Director ClickArte S.A.S. .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'Un septiembre en New York',
    year: 1993,
    author: 'Leo Comix',
    author2: 'Bernardo Rincón (director y portada)',
  },
  {
    title: 'Un Truco Peligroso',
    year: 2013,
    author: 'Felipe González Corredor (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Una avenida en el cielo',
    year: 1995,
    author: 'Pepe',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Leocomix (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló, Giovanni, (comité directivo)',
  },
  {
    title: 'Una historia de Orígenes',
    year: 2014,
    author: 'Mateo Nieto',
  },
  {
    title: 'Una historia diaria',
    year: 1994,
    author2: 'Bernardo Rincón (director)',
    author3: 'Juan Caros Ochoa (portada)',
  },
  {
    title: 'Uñas Largas',
    year: 2014,
    author: 'Camilo Aguirre (Artista)',
    author2: 'Othro Boros (Escritor)',
  },
  {
    title: 'Underground Fighters',
    year: 2019,
    author: 'Catman (Dibujante y escritor)',
  },
  {
    title: 'Unicornio',
    year: 2010,
    author: 'Barnett (Ilustrador)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Truchafrita (portada)',
    author4:
      'Sebastián Rubiano (Diseño) Pedro Giraldo, Mauricio Giraldo, Félix Salamanca (Asistencia editorial e ilustración)',
  },
  {
    title: 'Unombrepasivo',
    year: 1993,
    author: 'Flakoz',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores) Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Untitled 1',
    year: 1993,
    author: 'Caramelot',
    author2: 'Bernardo Rincón (director)',
    author3: 'Giovanni Castro (portada)',
  },
  {
    title: 'Untitled 2',
    year: 1995,
    author: 'Caramelot',
    author2: 'Gilles Fauveau (editor)',
    author3: 'Nigio (portada)',
    author4:
      'Alberto, Alex, Leocomix, Gilles, Nigio, Pepe, Quiló (comité directivo)',
  },
  {
    title: 'Valeska',
    year: 1997,
    author: 'Caramelot',
  },
  {
    title: 'Velocidad de escape',
    year: 2018,
    author: 'Henry González (Ilustrador)',
    author2: 'Andrés Rojas & Carlos Smith (creadores)',
    author3: 'Andrés Rojas (guión)',
    author4:
      'Lola Barreto (dirección de diseño), Tatiana Barragán (producción editorial), Ostake, Tatiana Huertas (maquetación)',
  },
  {
    title: 'Verdades que liberen',
    year: 2021,
    author: 'Comisión de la Verdad',
    author4:
      'Taller Creativo de Aleida Sánchez B. SAS Desarrollo creativo, producción editorial y audiovisual www.tallercreativoaleida.com.co Coordinación editorial y producción general Aleida Sánchez Buitrago Guionización para versiones gráfica y audiovisual, desarrollo de actividades pedagógicas Lorenzo Camacho Téllez Jorge Camacho Velásquez Ilustración original Mario Urazán Roncancio Diseño y diagramación Aleida Sánchez Buitrago Zamara Zambrano Sánchez .PUNTOAPARTE EDITORES Dylan Quintero Ilustración Jeisson Reyes Diagramación',
  },
  {
    title: 'Viaje al otro lado',
    year: 1997,
    author: 'Daniel Rabanal',
    author2: 'Bernardo Rincón (director)',
    author3: 'Alejandro Gutiérrez (portada) (México)',
  },
  {
    title: 'Viñetas frescas',
    year: 2022,
    author: 'Sebastián Salazar',
    author2: 'Diego A. García',
    author3: 'Rafael Cepeda V.',
    author4: 'Sergio A. Palacio Ángel Balanta',
  },
  {
    title: 'Viñetas negras La noche más oscura',
    year: 1992,
    author: 'Oscar Campo',
    author2: 'José Campo H.',
    author3: 'Velásquez (dibujo portada)',
    author4: 'Grosso (Concepto)',
  },
  {
    title: 'Viñetero',
    year: 2017,
    author: 'Luis Tobón (Autor)',
    author2: 'Jesús David Rodríguez (diagramación)',
  },
  {
    title: 'Virus Tropical',
    year: 2011,
    author: 'PowerPaola (Paola Gaviria)',
  },
  {
    title: 'Viuda negra',
    year: 1995,
    author: 'Bernardo Rincón',
    author3: 'Marco Pinto (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Viva la resistance!',
    year: 2012,
    author: 'Henry Diaz (dibujos) & Guido Barsi (guion)',
    author2: 'Daniel Jiménez Quiroz (director)',
    author3: 'Luto (portada)',
    author4: 'Jessica Garzón (diseño)',
  },
  {
    title: 'Vladimir',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Volk',
    year: 2016,
    author: 'Luis Miguel Ferro Arango',
    author2: 'Esteban Rojas',
  },
  {
    title: 'Vu avec mua?',
    year: 1993,
    author: 'Tyto Pérez',
    author2: 'Flakoz (portada)',
    author3: 'Diego Tobón (director)',
    author4:
      'Alejandro Lobo, Juan Marín (editores), Wil, Flakoz, Lobo, Acme (comité editorial)',
  },
  {
    title: 'Vudú para perras',
    year: 2008,
    author: 'Maucho',
    author2: 'Pedro José Giraldo (director)',
    author3: 'Nomás (Tomás Arango) (portada)',
    author4:
      'Miguel Giraldo, Mauricio Giraldo, Juan Francisco Jaramillo, Roberto Migaja (comité editorial) Daniel Jiménez Quiroz (editor) Juan Carlos Londoño (corrección)',
  },
  {
    title: 'Walter & Cía: El talismán del Zipa',
    year: 1995,
    author: 'Viktor (guión y dibujo)',
    author2: 'Marco Pinto (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Walter y Cýa en: El talismán de Zipa',
    year: 1995,
    author: 'Viktor',
    author2: 'Brügo (portada)',
    author4: 'Bernardo Rincón (director)',
  },
  {
    title: 'Wonderland',
    year: 2019,
    author: 'Kevin Correa (Autor)',
  },
  {
    title: 'Wood & Stock',
    year: 1992,
    author: 'Angeli',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Woodstock - Los viejos hippies',
    year: 1992,
    author: 'Teto',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Y dicen que una imagen vale más que mil palabras',
    year: 1992,
    author: 'Quir',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Yarumoradas',
    year: 1996,
    author: 'Ernesto Franco (Autor, Ilustrador)',
  },
  {
    title: 'Zafyranova',
    year: 2021,
    author: 'Daniel Greiff Tovar (Escritor)',
  },
  {
    title: 'Zape niño!',
    year: 1993,
    author: 'Maxo (Max Milfort Blandón)',
    author2: 'Andrés & Vargas (editores)',
    author4:
      'Diego Andrés Cardona Castro, Max Milfort Blandón (directores creativos)',
  },
  {
    title: 'Zipita &',
    year: 1976,
    author: 'Pepón José María López (caricaturista)',
    author4:
      'Juan Valverde, Hernando "Chato" Latorre Jr., Patricia Donald, Bernardo Rozo, Ronald Cristoffani, Hernando Campos',
  },
  {
    title: 'Zona Rosa',
    year: 1992,
    author: 'Bernardo Rincón',
    author2: 'Jorge Grosso (director)',
    author3: 'Bernardo Rincón (coordinador)',
    author4: 'Taller del humor',
  },
  {
    title: 'Zona Rosa',
    year: 1992,
    author: 'Bernardo Rincón',
    author2: 'Jorge Grosso (director)',
    author3: 'Mercy Yate (coordinador)',
    author4: 'Taller del humor Juan Lanas (gerente)',
  },
  {
    title: 'Zoo drive',
    year: 1994,
    author: 'Lucía Salazar V.',
    author2: 'Federmán Contreras Díaz (director)',
    author3: 'Alejandro Rubio (portada)',
    author4: 'Diego Sarmiento Herrera (Editor)',
  },
]
