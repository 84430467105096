import React, { useEffect, useState } from 'react'

import { TimelineComic, data } from './data'
// Style
import {
  timelineYear,
  timelineTitle,
  timelineCard,
  timelineDecade,
  timelineSlider,
  timelineSources,
  timelineWrapper,
  timelineDecadeImg,
  timelineDescription,
} from './style.module.scss'

const Timeline: React.FC = () => {
  const sortYear = (a: number | null, b: number | null) => {
    if (a === null) return 1
    if (b === null) return -1
    return a - b
  }

  const allComics = data
  const decades = [
    1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020,
  ]

  const [sorted, setSorted] = useState<TimelineComic[]>([])

  const [decade, setDecade] = useState<typeof decades[number] | null>(null)

  useEffect(() => {
    setSorted(
      allComics
        .sort((a, b) => sortYear(a.year, b.year))
        .filter(
          (c) => c.year && decade && c.year >= decade && c.year < decade + 10
        )
    )
  }, [decade])

  return (
    <>
      <h1 className={timelineTitle}>Línea de Tiempo</h1>
      <h3 className={timelineDescription}>
        ¿Te gustaría conocer los cómics que han marcado la historia de Colombia?
        ¿Quieres descubrir las obras maestras del noveno arte que han reflejado
        la realidad, la fantasía y la diversidad de nuestro país? Entonces no te
        pierdas esta increíble línea del tiempo, creada con el apoyo de FICCO
        (Festival Independiente de Cómic Colombiano). En ella encontrarás una
        selección de los cómics más representativos y relevantes que han
        acompañado el transcurso de la historia colombiana en el noveno arte,
        desde sus orígenes hasta la actualidad. <br /> <br />
        Esta línea del tiempo es un proyecto humilde pero muy dedicado, que
        busca ser una fuente de información y de inspiración para todos los
        amantes del cómic. Por eso, te invitamos a que nos ayudes a completarla
        y a enriquecerla con tus aportes y sugerencias. Si conoces algún cómic o
        historieta que creas que debe estar aquí, no dudes en contactarnos y en
        enviarnos tu propuesta. Queremos que esta sea la base de datos más
        completa y actualizada posible, y que sea un espacio de difusión para el
        cómic colombiano.
      </h3>
      <div className={timelineSlider}>
        {decades.map((m) => {
          if (
            allComics.some((c) => (c.year ?? 0) >= m && (c.year ?? 0) < m + 10)
          )
            return (
              <div className={timelineDecade} onClick={() => setDecade(m)}>
                <img
                  className={timelineDecadeImg}
                  src={`https://comiccolombiano.com/.img/timeline/${m}.png`}
                />
              </div>
            )
        })}
      </div>
      {decade && (
        <div className={timelineSlider}>
          {Array(10)
            .fill(decade)
            .map((m, i) => {
              if (allComics.some((c) => c.year === m + i))
                return (
                  <div className={timelineYear}>
                    <h2 style={{ textAlign: 'center' }}>{m + i}</h2>
                    <div className={timelineWrapper}>
                      {sorted
                        .filter((f) => f.year === m + i)
                        .map((c) => (
                          <div className={timelineCard}>
                            <div>
                              {c.title} - {c.author}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )
            })}
        </div>
      )}
      <div>
        <div className={timelineSources}>
          <i>
            <b>Fuentes:</b>
            <ul>
              <li>Bibliotecas Privadas</li>
              <li>comiccolombiano.com</li>
              <li>El Espectador</li>
              <li>
                Museo Virtual de la Historieta Colombiana (Universidad Nacional)
              </li>
              <li>Comisión de la Verdad</li>
              <li>Revista Blast</li>
            </ul>
          </i>
        </div>
      </div>
    </>
  )
}

export default Timeline
